<div class="dashboard-child-container no-padding list">
  <div style="display: flex; align-items: center;">
    <nz-tabset (nzSelectChange)="onTabChange($event)" [nzSelectedIndex]="tabIndex">
      <ng-container *ngFor="let tab of tabList">
          <nz-tab [nzTitle]="titleTemplate"></nz-tab>
          <ng-template #titleTemplate>
            <nz-badge [nzCount]="tab?.total" [nzOverflowCount]="999" nzShowZero [nzStyle]="{ backgroundColor: '#1b1ac4'}">
              <span>{{tab.name}}</span>
            </nz-badge>
          </ng-template>
      </ng-container>
    </nz-tabset>
    <div class="flex1"></div>

    <nz-input-group [nzPrefix]="iconSearch" style="width: 300px;">
      <input type="text" nz-input placeholder="Search by name or description" (keyup.enter)="inputSearchEnter($event)"/>
    </nz-input-group>
    <ng-template #iconSearch>
      <i nz-icon nzType="search"></i>
    </ng-template>
    <button class="left10" nz-button (click)="onBtnCreate()">
      <i nz-icon nzType="plus" nzTheme="outline"></i>Create
    </button>
    <button class="left10" nz-button (click)="onBtnRefresh()">
      <i nz-icon nzType="reload" nzTheme="outline"></i>
    </button>
  </div>

  <div class="list-body">
    <nz-table #nzTable 
      (nzPageIndexChange)="onDataListPageChanged($event)" 
      [nzData]="listData" [nzFrontPagination]="false" 
      [nzLoadingIndicator]="tplLoading" 
      [nzLoading]="isLoading"
      [nzNoResult]="tplNoData"
      [nzPageIndex]="pageIndex" 
      [nzPageSize]="limit"
      [nzShowPagination]="totalCount > listData.length" 
      [nzSize]="'default'" 
      [nzTotal]="totalCount" nzBordered="true"
      nzSize="small" style="font-size: 12px;"
    >
      <ng-template #tplNoData>
        <div *ngIf="!isLoading" class="nodata">
          <i nz-icon nzTheme="outline" nzType="search"></i>
          No results. Please try moving to the other tabs for more results.
        </div>
      </ng-template>

      <ng-template #tplLoading>
        <div class="nodata" style="min-height: 50px;">
          <i nz-icon nzTheme="outline" nzType="loading"></i>
        </div>
      </ng-template>

      <tbody>
        <ng-container *ngFor="let item of listData; let i = index">
          <tr 
            item-bid-session-component 
            [item]="item"
            (onItemDeleted)="onItemDeleted($event)"></tr>
        </ng-container>
      </tbody>
    </nz-table>
  </div>
</div>
