import { Component } from "@angular/core";
import { BaseForm, FormGroupDeclaration } from '@wearewarp/ng-form';
import { DateUtil, TimeZoneObject } from "@services/date-utils";
import { Const } from "@const/Const";
import { FormDataBidSessionBasicInfo } from "./interface";
import { ApiService } from "@services/api.service";
import { Utils } from "@services/utils";

@Component({
  selector: "bid-session-basic-info",
  templateUrl: "./index.html",
  styleUrls: ["./style.scss"],
})
export class BidSessionBasicInfo extends BaseForm<FormDataBidSessionBasicInfo> {

  protected formGroupDeclaration: FormGroupDeclaration = {
    name: { label: "Name (must be unique)", required: true },
    descShort: { label: "Short Description (showed on list screen)", required: true },
    descLong: { label: "Long Description (showed on detail screen)", required: true, multiline: true },
    timeFrame: { label: "", type: 'formGroup', required: true, childItem: {
      range: {label: 'Time Frame', required: true, placeHolder: ['Start date', 'End date']},
      timezone: { label: "Time zone", required: true },
    }},
    clientIds: { label: 'Customers', notAcceptEmpty: true, placeHolder: 'Select customers' },
    markets: { label: 'Markets', notAcceptEmpty: true, placeHolder: 'Select markets' },
    vehicleTypes: { label: 'Vehicles', notAcceptEmpty: true, placeHolder: 'Select vehicles' },
  };

  get keys() { return Object.keys(this.formGroupDeclaration) }

  timezones: TimeZoneObject[] = DateUtil.listUsTimezones.map(it => DateUtil.timezoneObjectFromShortName(it));
  listClients: any[] = [];
  listMarkets: any[] = [];
  allowEditFields: string[] | undefined;
  isGetAllAtOnce: boolean = false;

  ngOnInit() {
    this.initEditableFields();
    super.ngOnInit();
    this.fetchMarkets();
  }

  private initEditableFields() {
    if (this.isCreateNew) return;
    if (!Utils.isArrayNotEmpty(this.allowEditFields)) return;
    for (let key of this.keys) {
      if (!this.allowEditFields.includes(key)) {
        this.formGroupDeclaration[key].readOnly = true;
      }
    }
  }

  isLoadingMarket = false;
  private fetchMarkets() {
    this.isLoadingMarket = true;
    ApiService.instance.GET(Const.APIV2(`${Const.APIURI_METADATA}/ORG_0/SYSTEM/LIST_MARKETS`)).subscribe(
      (resp) => {
        let value = resp?.value || '[]';
        try {
          value = JSON.parse(value);
        }
        catch (e) {
          value = [];
        }
        this.isLoadingMarket = false;
        this.listMarkets = value;
      },
      (err) => {
        this.isLoadingMarket = false;
        console.error(`fetchMarkets failed `, err);
      }
    )
  }

  timezoneLabel(tz: TimeZoneObject) {
    return `${tz.shortName} (${tz.abbreviation})`;
  }
  
  getVehicleType() {
    const vehicleTypes = this.getItemValue('vehicleTypes');
    if (vehicleTypes?.length) return vehicleTypes;
    return null;
  }

  onValueChange(key, value) {
    switch (key) {
      case 'vehicleTypes':
        this.setItemValue(key, value);
        break;
    }
  }

}