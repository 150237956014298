import { Component, Input } from "@angular/core";
import { BaseComponent } from "@abstract/BaseComponent";
import { DialogService } from "@dialogs/dialog.service";
import { Subscription } from "rxjs";
import { BidSessionDetailService } from "@app/admin/bid-sessions/bid-session-detail.service";
import { DateUtil } from "@services/date-utils";
import { InputHelper } from "@services/input-helper";
import { Const } from "@const/Const";
import { ActivatedRoute } from "@angular/router";
import { AssignCarrier } from "@app/admin/carrier-bids/components/assign-carrier";
import { CarrierSaleDetailService } from "@app/admin/carrier-sale-detail/carrier-sale-detail.service";

@Component({
  selector: "bid-session-carriers",
  templateUrl: "./index.html",
  styleUrls: ["./index.scss"],
})
export class BidSessionCarriers extends BaseComponent {
  public limit: number = -1;
  public pageIndex: number = 1;
  public totalCount: number;
  private bidDetailSubscription: Subscription;
  @Input() bidSessionId: string = '';
  bidSession: any;
  jobs = [];
  responsedCandidates: any[] = [];
  bids: any[] = [];

  constructor(
    protected activatedRoute: ActivatedRoute, 
    private bidSessionDetailService: BidSessionDetailService,
    private carrierSaleDetailService: CarrierSaleDetailService
  ) {
    super();
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.fetchData();
  }1

  fetchData() {
    this.bidDetailSubscription = this.bidSessionDetailService.sessionDetail$.subscribe(sessionDetail => {
      this.bidSession = sessionDetail;
    });
    this.bidDetailSubscription = this.bidSessionDetailService.candidates$.subscribe(candidates => {
      this.responsedCandidates = candidates;
    });
    this.bidDetailSubscription = this.bidSessionDetailService.bids$.subscribe(bids => {
      this.bids = bids;
      const jobs = [];
      for(let bid of bids) {
        jobs.push({
          ...(bid.route || {}),
          bidId: bid.id,
          isRequireCarrierAcceptLoadTender: bid.isRequireCarrierAcceptLoadTender,
          countHasBids: bid.countHasBids || 0,
          status: bid.status,
          pickupTime: bid.pickupTime
        });
      }
      this.jobs = jobs;

      const jobHaveBids = jobs.filter(job => job.countHasBids && !job.assignedCarrier?.carrierId);
      const jobNoBids = jobs.filter(job => !job.countHasBids && !job.assignedCarrier?.carrierId);
      const jobAssigned = jobs.filter(job => job.assignedCarrier?.carrierId);
      this.jobs = [...jobHaveBids, ...jobNoBids, ...jobAssigned];
    });

    this.subscription.add(this.bidSessionDetailService.getCarrierTabFilter().subscribe(tabFilter => {
      if (!tabFilter) {
        this.onSetCarrierTabFilter();
      } else {
        this.pageIndex = tabFilter.pageIndex;
        this.limit = tabFilter?.limit;
        this.getListCarriers();
      }
    }));
  }

  ngOnDestroy() {
    if (this.bidDetailSubscription) {
      this.bidDetailSubscription.unsubscribe();
    }
  }

  private onSetCarrierTabFilter() {
    this.bidSessionDetailService.setCarrierTabFilter({
      pageIndex: this.pageIndex,
      limit: this.limit,
    });
  }

  onChangePage(value: number) {
    this.pageIndex = value;
    this.onSetCarrierTabFilter();
  }

  public isLoading: boolean = false;
  public candidates: any[] = [];
  getListCarriers() {
    this.isLoading = true;
    const skip = this.pageIndex ? (this.pageIndex - 1) * this.limit : 0;
    const url = `${Const.APIURI_BID_SESSIONS}/${this.bidSession.id}/candidates?filter=${encodeURIComponent(JSON.stringify({ type: 'response-received', search: '' }))}&skip=${skip}&limit=${this.limit}`;
    this.api.GET(url).subscribe(
      res => {
        this.isLoading = false;
        this.totalCount = res.data?.total;
        this.bidSessionDetailService.setCarriers(res.data?.list_data || []);
        this.candidates = res.data?.list_data || [];
      },
      error => {
        this.showErr(error);
        this.isLoading = false;
      }
    )
  }

  $formatPickupDate(pickupTime) {
    if(!pickupTime) return 'N/A';
    return DateUtil.format(pickupTime, 'DD MMM');
  }

  getCarrierName(item) {
    return item?.metadata?.carrier?.name || 'N/A';
  }

  $asCarrierStatusText(status) {
    const statusKey = Object.keys(Const.CarrierStatus).find((key) => Const.CarrierStatus[key] == status) || "";
    const text = this.getStatusCarrier(status);
    return text || this.capitalizeFirstLetter(statusKey);
  }

  getPrice(candidate, job, onlyPrice = false) {
    if(!this.responsedCandidates?.length) return '';
    const item  = this.responsedCandidates.find(c => c.carrierId === candidate.carrierId && c.bidId === job.bidId);
    if(onlyPrice) return item?.price;
    return item?.price ? this.$asMoney(item?.price) :  '';
  }

  $asMoney = (money) => {
    return InputHelper.formatMoney1(money + '');
  };

  getAssignedJob(candidate) {
    return this.jobs.filter(job => job?.assignedCarrier?.carrierId === candidate.carrierId).length;
  }

  getBidStatus(job) {
    if(job.status === 'completed') return 'completed';
    if(!job.countHasBids) return 'warning';
    return '';
  }

  isAssigned(candidate, job): boolean {
    const item = this.jobs.find(it => it?.assignedCarrier?.carrierId === candidate.carrierId && it.id === job.id);
    return !!item;
  }

  sortBidByRoute(job) {
    let results = [];
    for(let candidate of this.candidates) {
      let price = this.getPrice(candidate, job, true) || 999999;
      results.push({
        carrierId: candidate.carrierId,
        price
      })
    }
    results = results.sort((a, b) => a.price - b.price);
    const candidates = []
    for(let item of results) {
      const candidate = this.candidates.find(c => c.carrierId === item.carrierId);
      candidates.push(candidate);
    }
    this.candidates = candidates;
  }

  sortBidsByCarrier(candidate) {
    let results = [];
    for(let job of this.jobs) {
      let price = this.getPrice(candidate, job, true) || 999999;
      results.push({
        bidId: job.bidId,
        price
      })
    }
    results = results.sort((a, b) => a.price - b.price);
    const jobs = []
    for(let item of results) {
      const job = this.jobs.find(j => j.bidId === item.bidId);
      jobs.push(job);
    }
    this.jobs = jobs;
  }

  removeRoute(job) {
    console.log('removeRoute', job);
    this.confirmDeletion({
      message: `Are you sure you want to remove route(${job.code}) from session ?`,
      fnOk: () => {
        this.api.POST(`${Const.APIURI_BID_SESSIONS}/${this.bidSession.id}/remove-route`, {
          jobId: job.id
        }).subscribe(
          (response) => {
            this.fetchData();
            this.showInfo('Remove successfully');
          },
          (err) => {
            this.showErr(err);
          }
        );
      },
      txtBtnOk: 'Remove'
    });
  }

  onBtnAcceptBid(candidate, job) {
    const price = this.getPrice(candidate, job, true);
    const exist = this.jobs.find(it => it.id === job.id);
    console.log('job', exist, price);
    if(!exist) return;

    let cost = this.carrierSaleDetailService.makeCost({ price });
    DialogService.openFormDialog1(AssignCarrier, {
      nzComponentParams: {
        jobId: exist.id,
        isRequireCarrierAcceptLoadTender: exist?.isRequireCarrierAcceptLoadTender ?? false,
        matchedCarrier: candidate,
        cost: cost,
        job: exist,
        closeOnSuccess: true,
        updateSuccess: resp => {  
          this.showDialog(`Carrier has been assigned successfully.`);        
          // this.showDialog(`Carrier has been assigned successfully.<br /><br />
          //   <a href="${this.gotoDispatch()}" target="_blank">Go to dispatch</a>
          // `);
        }
      },
      nzClassName: 'modal-no-padding assign-carrier-form',
    });
  }

  isShowRemoveRoute(job): boolean {
    return !job?.assignedCarrier?.carrierId;
  }
}