import { ChangeDetectorRef, Component, Injector, Input, OnDestroy, OnInit } from "@angular/core";
import { DataRepoBidSession } from "@app/data-repo/bid-session";
import { DataLoader } from "@app/model/DataLoader";
import { Const } from "@const/Const";
import { DialogService } from "@dialogs/dialog.service";
import { DateUtil } from "@services/date-utils";
import { UIHelper } from "@services/UIHelper";
import { Utils } from "@services/utils";
import { ResponseBidSessionSearchRoutesItem } from "@wearewarp/types-server-admin/carrier-bid/bid-session";
import { PaginationData } from "@wearewarp/types/common/interface";
import { FormDataBidSessionFilterRoute, FormDataBidSessionFilterRouteDisplay } from "../form-basic-info/interface";
import { NzModalService } from "ng-zorro-antd/modal";
import { getInjector } from '@services/index';

@Component({
  selector: "bid-session-add-routes",
  templateUrl: "./view.html",
  styleUrls: ["./style.scss"],
})
export class BidSessionAddRoutes implements OnInit, OnDestroy {
  @Input() sessionId: string;
  @Input() readonly filter: {condition: FormDataBidSessionFilterRoute, display: FormDataBidSessionFilterRouteDisplay};
  @Input() onReload: any;

  get txtDateTime() {return this.filter.display.timeFrame}
  get customers() {return this.filter.display.customers}
  get markets() {return this.filter.display.markets}
  get vehicles() {return this.filter.display.vehicles}
  get routeAdminClientList() {return Const.routeAdminClientList}

  private dataLoader_routesRecommend = new DataLoader<PaginationData<ResponseBidSessionSearchRoutesItem>>();
  private dataLoader_routesAddded = new DataLoader<PaginationData<ResponseBidSessionSearchRoutesItem>>();

  readonly routesLoaders = [
    {
      key: 'recommend',
      name: 'Recommended routes that meet the above conditions',
      noDataText: 'No route meets the above conditions.',
      listData: () => this.dataLoader_routesRecommend.data?.list_data ?? [],
      isLoading: () => this.dataLoader_routesRecommend.isLoading,
      actions: [
        {label: 'Add', fn: item => this.addRouteToSession(item)}
      ],
    },
    {
      key: 'added',
      name: 'Added routes',
      noDataText: 'No route has been added yet.',
      listData: () => this.dataLoader_routesAddded.data?.list_data ?? [],
      isLoading: () => this.dataLoader_routesAddded.isLoading,
      actions: [
        {label: 'Remove', fn: item => this.removeRouteFromSession(item), isDanger: true}
      ],
    },
  ]

  protected injector: Injector;
  protected modalService: NzModalService;
  constructor(private repoBidSession: DataRepoBidSession, private cdr: ChangeDetectorRef) {
    this.injector = getInjector();
    this.modalService = this.injector.get(NzModalService);
  }

  ngOnInit(): void {
    this.getRoutes();
  }

  ngOnDestroy(): void {
    this.dataLoader_routesRecommend.dispose();
    this.dataLoader_routesAddded.dispose();
  }

  private getRoutes() {
    this.dataLoader_routesRecommend.load(this.repoBidSession.getRoutesRecommend(this.sessionId), {error: err => UIHelper.showErr(err)});
    this.dataLoader_routesAddded.load(this.repoBidSession.getRoutesAdded(this.sessionId), {error: err => UIHelper.showErr(err)});
    this.onReload();
  }

  private reloadRoutes() {
    this.getRoutes();
  }
  
  linkRoute(route: ResponseBidSessionSearchRoutesItem) {
    return [Const.routeAdminDispatchList, route.id];
  }
  
  linkCustomer(customer: {id: string}) {
    return [Const.routeAdminClientList, customer.id];
  }

  pickAddr(route: ResponseBidSessionSearchRoutesItem) {
    return `${route.pick.addr.city}, ${route.pick.addr.state}, ${route.pick.addr.zipcode}`;
  }

  pickTime(route: ResponseBidSessionSearchRoutesItem) {
    let time = DateUtil.displayTimeWindow(route.pick.window, {timezone: route.pick.addr.timezone, format: 'MMM D, YYYY h:mm a', formatDateOnly: 'MMM D, YYYY'});
    let tz = DateUtil.timezoneStandardToUsShort(route.pick.addr.timezone);
    return `${time} (${tz})`;
  }

  dropAddr(route: ResponseBidSessionSearchRoutesItem) {
    return `${route.drop.addr.city}, ${route.drop.addr.state}, ${route.drop.addr.zipcode}`;
  }

  dropTime(route: ResponseBidSessionSearchRoutesItem) {
    let time = DateUtil.displayTimeWindow(route.drop.window, {timezone: route.drop.addr.timezone, format: 'MMM D, YYYY h:mm a', formatDateOnly: 'MMM D, YYYY'});
    let tz = DateUtil.timezoneStandardToUsShort(route.drop.addr.timezone);
    return `${time} (${tz})`;
  }

  vehicle(route: ResponseBidSessionSearchRoutesItem) {
    if (!route.vehicle) return 'N/A';
    let str = route.vehicle.name;
    if (route.vehicle.options.length) {
      str += ` w/ `;
      str += route.vehicle.options.map(it => Utils.capitalize(it)).join(', ');
    }
    return str;
  }

  addRouteToSession(route: ResponseBidSessionSearchRoutesItem) {
    this.repoBidSession.addRouteToSession(this.sessionId, route.id).subscribe(
      resp => {
        this.reloadRoutes();
        UIHelper.showSuccess(`Route has been added successfully`);
      }, err => UIHelper.showErr(err)
    )
  }

  removeRouteFromSession(route: ResponseBidSessionSearchRoutesItem) {
    DialogService.confirmDeletion({message: `Remove route ${route.code} from session?`, fnOk: () => {
      this.repoBidSession.removeRouteFromSession(this.sessionId, route.id).subscribe(
        resp => {
          this.reloadRoutes();
          UIHelper.showSuccess(`Route has been removed successfully`);
        }, err => UIHelper.showErr(err)
      )
    }})
  }

  addRouteToSessions(routes: ResponseBidSessionSearchRoutesItem[]) {
    const routeIds = routes.map(it => it.id);
    this.repoBidSession.addRouteToSessions(this.sessionId, routeIds).subscribe(
      resp => {
        this.reloadRoutes();
        UIHelper.showSuccess(`Route has been added successfully`);
      }, err => UIHelper.showErr(err)
    )
  }

  isCheckAllRecommend: boolean = false;
  pageSize: number = 10;
  onCheckAllRecommend(loader, isChecked: boolean) {
    const data = this.listOfCurrentPageData[loader.key];
    this.modalService.confirm({
      nzTitle: 'Are you want add all recommended routes in current page to session?',
      nzClosable: false,
      nzMaskClosable: false,
      nzCentered: true,
      nzOkText: 'Yes',
      nzOnOk: () => {
        this.isCheckAllRecommend = false;
        this.addRouteToSessions(data);
        this.cdr.detectChanges();
      },
      nzCancelText: 'No',
      nzOnCancel: () => {
        this.isCheckAllRecommend = false;
        this.cdr.detectChanges();
      }
    });
  }

  listOfCurrentPageData = {};
  onCurrentPageDataChange(key: 'recommend' | 'added', listOfCurrentPageData: readonly any[]): void {
    this.listOfCurrentPageData[key] = listOfCurrentPageData;
  }
}