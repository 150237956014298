import { Component, OnInit, ViewChild } from '@angular/core';
import { Const } from '@const/Const';
import { BaseFormDialog1 } from '@dialogs/base-form-dlg1';
import { CarrierSalePoolList } from '../select-list-carrier-pools';

@Component({
  selector: 'carrier-sale-add-carrier-pool',
  templateUrl: './index.html',
  styleUrls: ['./style.scss']
})
export class AddCarrierPool extends BaseFormDialog1 implements OnInit {
  constructor() {
    super();
  }

  @ViewChild('tablePools') tablePools: CarrierSalePoolList;

  canClickOK(): boolean {
    return (this.tablePools?.selectedItems?.length || 0) > 0
  }

  protected onUpdateSuccess(resp) {
    if (resp && resp.ui_message) {
      if(resp.isWarning) this.showWarning("", resp.ui_message);
      else this.showInfo(resp.ui_message);
    } else {
      this.showInfo("The item has been updated successfully.");
    }
    this.updateSuccess(resp);
    if (this.closeOnSuccess) {
      this.closeDialog();
    }
  }

  loading = false;
  updateCarrier(): void {
    this.loading = true;
    let carriers = []
    // const currentCarriers = this.model?.metadata?.carriers || []
    // for (let carrier of currentCarriers) {
    //   carriers.push({ carrierId: carrier?.carrierId })
    // }
    let poolIds: string[] = [];
    const pools: any = this.tablePools.selectedItems || [];
    for (let pool of pools) {
      const subjectIds = pool.subjectIds || [];
      if (subjectIds.length) {
        poolIds.push(pool.id);
        const newCarriers = subjectIds.map(it => { return { carrierId: it } });
        carriers = [...carriers, ...newCarriers];
      }
    }
    this.api.POST(`${Const.APIURI_CARRIER_BIDS}/${this.model?.id}/update-carrier`, {
      carriers: carriers,
      poolIds
    }).subscribe(
      (resp) => {
        this.stopProgress();
        resp.isWarning = carriers.length > 300;
        resp.ui_message = carriers.length > 300 ? "There are over 300 carriers on this bid. Please ensure all recipients are suitable to avoid excessive emails or SMS" : "Add carrier successfully";
        this.onUpdateSuccess(resp)
      },
      (err) => {
        this.showErr(err);
        this.stopProgress();
      }
    );
    setTimeout(() => {
      this.tablePools.clearAllSelected();
      this.loading = false;
    }, 1000);
  }
}
