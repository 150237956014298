import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NzCheckboxModule } from 'ng-zorro-antd/checkbox';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { NzTableModule } from 'ng-zorro-antd/table';
import { NzTagModule } from 'ng-zorro-antd/tag';
import { BidSessionAddRoutes } from '.';
import { NzCollapseModule } from 'ng-zorro-antd/collapse';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    NzButtonModule,
    FormsModule,
    ReactiveFormsModule,
    NzCheckboxModule,
    NzCollapseModule,
    NzIconModule,
    NzTableModule,
    NzTagModule,
  ],
  declarations: [
    BidSessionAddRoutes
  ],
  exports: [
    BidSessionAddRoutes
  ],
  providers: [
  ]
})
export class ModuleBidSessionAddRoutes {}