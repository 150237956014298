import { Component } from "@angular/core";
import { Const } from "@const/Const";
import { ApiService } from "@services/api.service";
import { getDashboard } from "@services/index";
import { BaseDetail } from "@app/admin/base/detail";
import { ActivatedRoute } from "@angular/router";
import { Subscription } from "rxjs";
import { BidSessionDetailService } from "@app/admin/bid-sessions/bid-session-detail.service";
import { Title } from "@angular/platform-browser";
import { StringULID } from "@wearewarp/types";
import { RequestBidSessionUpdate, ResponseBidSessionDetail, RequestAddCandidateToSession } from "@wearewarp/types-server-admin/carrier-bid/bid-session";
import { DateUtil } from "@services/date-utils";
import { DialogService } from "@dialogs/dialog.service";
import { AddCarrier } from "@app/admin/carrier-sale-detail/component/carriers/add-carrier";
import { ModalHelper } from "@wearewarp/ng-antd";
import { UIHelper } from "@services/UIHelper";
import { BidSessionBasicInfo } from "../form-basic-info";
import { detailToFormData, formDataToRequestUpdate } from "../form-basic-info/helper";
import { DataRepoBidSession } from "@app/data-repo/bid-session";
import { FormDataBidSessionBasicInfo, FormDataBidSessionFilterRoute, FormDataBidSessionFilterRouteDisplay } from "../form-basic-info/interface";
import { BidSessionAddRoutes } from "../form-add-route";
import { Utils } from "@services/utils";

@Component({
  selector: "bid-session-detail",
  templateUrl: "./index.html",
  styleUrls: ["./style.scss"],
})
export class BidSessionDetailComponent extends BaseDetail {

  public isLoading = false;
  public data: ResponseBidSessionDetail;
  public isError: boolean = false;
  get hasData() { return this.data }

  protected subscribeTopbarTitle(fn: Function) {
    return fn("Bid Session Detail");
  }

  private getDataSubscription: Subscription;

  constructor(
    private titleService: Title,
    private bidSessionDetailService: BidSessionDetailService,
    private modalHelper: ModalHelper,
    activatedRoute: ActivatedRoute,
    api: ApiService,
    private repoBidSession: DataRepoBidSession,
  ) {
    super(activatedRoute);
    this.api = api;
    this.activatedRoute.params.subscribe(params => {
      super.handleNavigationEnd(activatedRoute.toString(), activatedRoute.queryParamMap);
    })
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.getDataSubscription = this.bidSessionDetailService.getDataObservable().subscribe(() => {
      this.getData();
    });
    setTimeout(() => getDashboard().sideBar.isSmallWidth = true, 1);
  }

  ngOnDestroy(): void {
    if (this.getDataSubscription) {
      this.getDataSubscription.unsubscribe();
    }
  }

  protected getData() {
    this.isLoading = true;
    this.repoBidSession.getSessionDetail(this.id).subscribe(
      data => {
        this.data = data;
        this.titleService.setTitle(`${this.data?.name} - BID Session - WARP Admin`);
        this.buildDisplayData(this.data);
        this.bidSessionDetailService.setSessionDetail(this.data);
        this.bidSessionDetailService.setBids(this.data.bids);
        const bidIds = this.data.bids.map(it => it.id);
        this.getCarrierResponsed(bidIds);
        this.isLoading = false;
      }, err => {
        this.showErr(err);
        this.isLoading = false;
      }
    )
  }

  private reloadBasicInfo() {
    this.repoBidSession.getSessionDetail(this.id).subscribe(
      data => {
        this.data = data;
        this.buildDisplayData(this.data);
      }, err => {
        this.showErr(err);
      }
    );
  }

  txtDateTime = '';
  get carrierIds(): string[] { return this.carriers.map(it => it.id) }
  routes: {id: string, code: string, bidId: string}[] = [];
  carriers: {id: string, name: string}[] = [];
  customers = [];
  markets = [];
  vehicles = [];
  totalCarrier: number = 0;
  totalBid: number = 0;
  private buildDisplayData(data: ResponseBidSessionDetail) {
    this.txtDateTime = DateUtil.displayTimeWindow({from: data.fromTime, to: data.toTime}, {timezone: data.timeZone, format: 'MMM D, YYYY', formatDateOnly: 'MMM D'});
    this.txtDateTime += ` (${DateUtil.timezoneStandardToUsShort(data.timeZone)})`;
    this.routes = data.bids.map(it => ({...it.route, bidId: it.id}));
    this.carriers = data.candidates.map(it => it.carrier);
    this.carriersOfPage = this.carriers.slice((this.pageIndex - 1) * this.pageSize, this.pageIndex * this.pageSize);
    this.customers = data.clients ?? [];
    this.vehicles = data.vehicleTypes ?? [];
    this.markets = data.markets ?? [];
  }

  private getCarrierResponsed(bidIds: StringULID[]) {
    const urls = bidIds.map(bidId => Const.APIV2(`${Const.APIURI_CARRIER_BIDS}/${bidId}/get_list_carriers?filter=${encodeURIComponent(JSON.stringify({ type: 'response-received' }))}&limit=-1`));
    this.api.concurrentGET(urls).subscribe(
      res => {
        const candidates = res.map(r => r.data?.list_data || []).flat();
        this.totalCarrier = Utils.uniqElementsArray(candidates.map(it => it.carrierId)).length;
        this.totalBid = candidates.length;
        this.bidSessionDetailService.setCandidatesResponsed(candidates);
      },
      error => {
        this.showErr(error);
      }
    )
  }

  onBtnEdit() {
    // Nếu đã add route rồi thì không cho edit các trường mang tính chất filter nữa.
    const hasRoutesAdded = this.data.bids.length > 0;
    const editableFields = hasRoutesAdded ? ['name', 'descShort', 'descLong'] : undefined;
    this.modalHelper.openForm(BidSessionBasicInfo, {
      nzTitle: 'Update bid session info',
      nzComponentParams: {
        model: detailToFormData(this.data),
        listClients: this.data.clients,
        allowEditFields: editableFields,
        submit: data => this.updateBasicInfo(data)
      },
      nzClassName: 'modal-create-bid-session',
      canSubmit: comp => comp.canSubmit(),
      onSubmitError: err => UIHelper.showErr(err),
      onSubmitSucceeded: () => this.reloadBasicInfo(),
      editMode: 'patch',
    });
  }

  private updateBasicInfo(formData: Partial<FormDataBidSessionBasicInfo>) {
    const requestData: RequestBidSessionUpdate = formDataToRequestUpdate(formData);
    return this.repoBidSession.updateSession(this.id, requestData);
  }

  addRoute() {
    const model = detailToFormData(this.data);
    const filterCondition: FormDataBidSessionFilterRoute = {
      timeFrame: model.timeFrame,
      clientIds: model.clientIds,
      markets: model.markets,
      vehicleTypes: model.vehicleTypes,
    };
    const filterDisplay: FormDataBidSessionFilterRouteDisplay = {
      customers: this.customers,
      markets: this.markets,
      vehicles: this.vehicles,
      timeFrame: this.txtDateTime
    }
    this.modalHelper.open(BidSessionAddRoutes, {
      nzTitle: `Add routes to the bid session <b>${this.data.name}</b>`,
      nzComponentParams: {
        sessionId: this.id,
        filter: {
          condition: filterCondition,
          display: filterDisplay,
        },
        onReload: this.getData.bind(this),
      },
      nzFooter: null,
      nzClassName: `modal-fullscreen`
    });
  }

  addCarrier() {
    DialogService.openFormDialog1(AddCarrier, {
      nzComponentParams: {
        model: {},
        closeOnSuccess: true,
        currentCarrierIds: this.carrierIds,
        submit: async (carrierIds: string[]) => this.addCarrierToSession(carrierIds),
        updateSuccess: (resp) => {
        },
      },
      nzClassName: "send-mail-form modal-xl",
    });

  }

  private addCarrierToSession(listIds: string[]) {
    const requestData: RequestAddCandidateToSession = { carrierIds: listIds };
    return this.repoBidSession.addCandidates(this.id, requestData).toPromise();
  }

  pageSize: number = 10;
  pageIndex: number = 1;
  carriersOfPage = [];
  changePageIndex(value) {
    this.pageIndex = value;
    this.carriersOfPage = this.carriers.slice((this.pageIndex - 1) * this.pageSize, this.pageIndex * this.pageSize);
  }
}

