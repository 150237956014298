<div class="section-table">
  <div class="box-content top20">
    <div class="flex-space-between">
      <div class="carrier-tab">
        <nz-tabset
          [nzSelectedIndex]="selectedTabIndex"
          [nzType]="'line'" [nzSize]="'small'"
          (nzSelectedIndexChange)="onTabSelectedIndexChange($event)"
        >
          <nz-tab [nzTitle]="getTitleTab('response-received')"></nz-tab>
          <nz-tab [nzTitle]="getTitleTab('no-response')"></nz-tab>
        </nz-tabset>
      </div>
      <div class="action">
        <nz-space class="group-button">
          <ng-container *ngIf="requirePermissions([PermissionCode.candidate.del_all_in_bid])">
            <button *nzSpaceItem nz-button nzType="default" nzDanger (click)="removeAllCandidate()">
              <span nz-icon nzType="delete" nzTheme="outline"></span>
              Remove All Candidates
            </button>
          </ng-container>
          <button *nzSpaceItem nz-button nzType="primary" (click)="confirmSendRemind()" [disabled]="isDisableBtnSendRemind">
            <span nz-icon nzType="send" nzTheme="outline"></span>
            Send Remind
          </button>
          <button *nzSpaceItem nz-button nzType="primary" (click)="confirmSendAsap()" [disabled]="isDisableBtnAsap">
            <span nz-icon nzType="send" nzTheme="outline"></span>
            Send ASAP
          </button>
          <button *nzSpaceItem nz-button nzType="primary" nz-dropdown [nzDropdownMenu]="menu" [disabled]="isDisableBtnAddCarrier">
            Add Carriers
            <span nz-icon nzType="down"></span>
          </button>
        </nz-space>

        <nz-dropdown-menu #menu="nzDropdownMenu">
          <ul nz-menu>
            <li nz-menu-item (click)="openAddCarrierModal()" [nzDisabled]="isDisableBtnAddCarrier">
              <span nz-icon nzType="plus" nzTheme="outline"></span>
              <span>Add Carrier</span>
            </li>
            <li nz-menu-item (click)="openAddCarrierPoolModal()" [nzDisabled]="isDisableBtnAddCarrier">
              <span nz-icon nzType="plus" nzTheme="outline"></span>
              <span>Add Carrier Pools</span>
            </li>
            <li nz-menu-item (click)="onAddCarriersByArea()" [nzDisabled]="isDisableBtnAddCarrier">
              <span nz-icon nzType="plus" nzTheme="outline"></span>
              <span>Add Carriers by Area</span>
            </li>
            <li nz-menu-item (click)="onAddPoolsByArea()" [nzDisabled]="isDisableBtnAddCarrier">
              <span nz-icon nzType="plus" nzTheme="outline"></span>
              <span>Add Pools by Area</span>
            </li>
          </ul>
        </nz-dropdown-menu>
      </div>
    </div>
    <div class="section-search bottom10">
      <search-box
        #searchBox (doSearch)="doSearch($event)"
        [placeHolder]="'Search by Carrier name, Carrier contact'"
        [searchKeyword]="searchKeyword"
      ></search-box>
    </div>

    <div *ngIf="isActiveResponseReceivedTab">
      <div carrier-sale-tab-response-received 
        [bid]="bid" [candidates]="candidates" [contactInfos]="contactInfos"
        (onReload)="reload()"
      ></div>
    </div>

    <div *ngIf="isActiveNoResponseTab">
      <div carrier-sale-tab-no-response
      [bid]="bid" [candidates]="candidates" [contactInfos]="contactInfos"
      [totalCount]="totalCount"
      (pageChange)="onChangePage($event)"
      (onReload)="reload()"
      ></div>
    </div>
  </div>
</div>
