import { Component, EventEmitter, Input, Output } from "@angular/core";
import { Utils } from "@services/utils";
import _ from 'underscore';
import { Const } from "@const/Const";
import { DialogService } from "@dialogs/dialog.service";
import { Const as WarpConst } from "@wearewarp/universal-libs";
import { CarrierSaleDetailService } from "@app/admin/carrier-sale-detail/carrier-sale-detail.service";
import { BaseComponent } from "@abstract/BaseComponent";
import { BidCandidate, Contact } from "@wearewarp/types/data-model";
import { AddCarrier } from "../add-carrier";
import { AddCarrierPool } from "../add-carrier-pool";
import { AddCarrierByServiceArea } from "../add-carrier-by-area";
import { AddCarrierPoolByArea } from "../add-carrier-pool-by-area";
import { SendASAPDlg } from "../send-asap";
import { DateUtil } from "@services/date-utils";
import { CarrierBidEvent } from "@app/socket/helper/bid";
import { ActivatedRoute } from "@angular/router";
import { SocketEvent } from "@app/enum";
import { EventData_CarrierReplyBid } from "@wearewarp/types/event/exchange/bid";
import { forkJoin, Subscription } from "rxjs";

enum TabFilter {
  responseReceived = 'response-received',
  noRessponse = 'no-response'
}

@Component({
  selector: "carrier-sale-carrier-result",
  templateUrl: "./index.html",
  styleUrls: ["./index.scss"],
})
export class CarrierSaleCarrierResult extends BaseComponent {
  constructor(
    private route: ActivatedRoute,
    private carrierSaleDetailService: CarrierSaleDetailService, 
    private carrierBidEvent: CarrierBidEvent
  ) {
    super();
  }

  @Input() bid: any;
  @Output() onReload: EventEmitter<any> = new EventEmitter<any>();

  public isLoading: boolean = false;
  public candidates: (BidCandidate & {
    noteCount: number,
    notes: any,
    pinnedItems: any[],
    goHighWayLink?: string,
    goHightWayClassifications?: string,
    goHightWayStatus?: string
    countOfJob: number,
    countOfJobRateLike: number,
    countOfJobRateDislike: number,
    lastCompleteJob?: any,
    countLane: number,
    countJobUnassigned: number,
    lastCost?: any,
    contacts: Array<Contact & {
      contactEmail?: string,
      contactPhone?: string,
    }>,
    lastSentSms?: any,
    lastSentEmail?: any,
  })[] = [];
  public totalCount: number;
  public responsedCount: number = 0;
  public noResponseCount: number = 0;
  private notSentCount: number = 0;
  private sentButNoResponseCount: number = 0;
  public limit: number = Const.PAGINATION_LIMIT;
  public pageIndex: number = 1;
  private _selectedTabIndex = 0;
  public isCountLoading: boolean = false;
  carrierIds: string[] = [];
  contactInfos: any[] = [];
  bidCandidateIds: string[] = [];
  isOverPickupTime: boolean = false;
  ngOnInit(): void {
    super.ngOnInit();
    this.initSubscriptions();
  }

  private initSubscriptions() {
    const bidId = this.route.snapshot.paramMap.get('id');
    const subs: Subscription[] = [
      this.carrierSaleDetailService.getCarrierTabFilter().subscribe(tabFilter => {
        if (!tabFilter) {
          this.onSetCarrierTabFilter();
        } else {
          this.pageIndex = tabFilter.pageIndex;
          this._selectedTabIndex = tabFilter?.selectedTabIndex;
          this.limit = tabFilter?.limit;
          this.searchKeyword = tabFilter.searchKeyword; 
            this.getListCarriers();
            this.getCountCarriersForTabs();
        }
      }),
      this.carrierSaleDetailService.noteCountSubject.subscribe((data) => {
        this.candidates = this.candidates.map((item) => {
          item.noteCount = data[item.carrierId || item.requestId] || 0;
          return item;
        });
      }),
      this.carrierSaleDetailService.notesSubject.subscribe((data) => {
        this.candidates = this.candidates.map((item) => {
          const list = data[`CARRIER_${item.carrierId || item.requestId}_BID_${item.bidId}`] || [];
          const { notes, pinnedItems = [] } = this.formatDataNote(list) || {};
          item.notes = notes;
          item.pinnedItems = pinnedItems;
          return item;
        });
      }),
      this.carrierSaleDetailService.isOverPickupTime$.subscribe(data => {
        this.isOverPickupTime = data;
      }),
      this.carrierBidEvent.subscribeBid(bidId, data => {
        switch (data.event) {
          case SocketEvent.carrierBidReply: return this.onCarrierReplyBid(<EventData_CarrierReplyBid>data.payload)
        }
      }),
    ];
    subs.map(sub => this.subscription.add(sub));
  }

  private onCarrierReplyBid(data: EventData_CarrierReplyBid) {
    for (let item of this.candidates) {
      if (item.carrierId == data.carrierId) {
        // carrier đã nằm trong list candidate reply từ trước rồi => event sẽ xử lý theo candidate cụ thể ở component candidate
        return;
      }
    }
    // khi có 1 carrier mới (chưa reply bao giờ) reply thì cần reload data
    this.getListCarriers();
    this.onReload.emit();
  }

  formatDataNote(data) {
    let items = {};
    let pinnedItems = [];
    for (let note of data) {
      if (Utils.isArrayNotEmpty(note?.imageUploadFilesArr)) {
        for (let item of note.imageUploadFilesArr) {
          item.imgUrl = this.attachedFileUrl(item);
        }
      }
      let date = note.insert?.when;

      if (note.pinned?.when && note.pinned?.by) {
        pinnedItems.push(note);
        note['time'] = DateUtil.dateToString(date, "MM/DD/YY hh:mm A");
        continue;
        //nếu note được pined thì đẩy lên đầu và không hiển thị lại ở phía dưới.
      }

      note['time'] = DateUtil.dateToString(date, "hh:mm A")


      //group note by day
      if (DateUtil.isToday(date)) date = "Today";
      else if (DateUtil.isYesterday(date)) date = "Yesterday";
      else date = DateUtil.dateToString(date, Const.FORMAT_GUI_DATE);


      if (!items[date]) items[date] = [];
      items[date].push(note);
    }
    pinnedItems = pinnedItems.sort(function (a, b) {
      let aDate = new Date(a.pinned.when);
      let bDate = new Date(b.pinned.when);
      return aDate.getTime() < bDate.getTime() ? 1 : (aDate.getTime() > bDate.getTime() ? -1 : 0)
    })
    return { notes: items, pinnedItems };
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  getListCarriers() {
    this.isLoading = true;
    this.api.GET(this.getApiByTab(this.currentTabFilter)).subscribe(
      res => {
        this.isLoading = false;
        this.carrierSaleDetailService.setCarriers(res.data?.list_data || []);
        this.candidates = this.onGetDataSucceeded(res.data?.list_data || []);
        this.totalCount = res.data?.total;
        this.carrierIds = [];
        this.contactInfos = [];
        this.bidCandidateIds = [];
        for (const item of this.candidates) {
          if (item.id && !this.bidCandidateIds.includes(item.id)) {
            this.bidCandidateIds.push(item.id);
          }
          if (!this.carrierIds.includes(item.carrierId)) {
            this.carrierIds.push(item.carrierId);
          }
          const contactPhone = item.contacts?.[0]?.contactPhone;
          const contactEmail = item.contacts?.[0]?.contactEmail;
          const countryCode = "+1";
          if (contactPhone) {
            const owner = contactPhone.replace(/[^0-9]/g, "");
            const conditions = {
              origin: contactPhone,
              owner: countryCode + owner,
              type: 'phone',
            };
            this.contactInfos.push(conditions);
          }
          if (contactEmail) {
            const owner = contactEmail.toLowerCase();
            const conditions = {
              origin: contactEmail,
              owner,
              type: 'email',
            };
            this.contactInfos.push(conditions);
          }
        }
        const candidateRefused = this.candidates.filter(item => item.state === Const.CarrierBidState.Refused);
        if (candidateRefused.length) {
          const candidateNotRefused = this.candidates.filter(item => item.state !== Const.CarrierBidState.Refused);
          this.candidates = [...candidateNotRefused, ...candidateRefused];
        }

        this.goHighWayInfoForByCarrieIds();
        this.performanceForCarrierList();
        this.checkUnsubscribe();
        this.commlogHistoriesByCandidate();
      },
      error => {
        this.showErr(error);
        this.isLoading = false;
      }
    )
  }

  goHighWayInfoForByCarrieIds() {
    if (!this.carrierIds?.length) return;
    const url = `${Const.APIV2(Const.APIURI_CARRIER_BIDS)}/go-highWay-info-for-by-carries`;
    return this.api.POST(url, { carrierIds: this.carrierIds }).subscribe(
      (resp) => {
        const data = resp?.data?.list_data ?? [];
        this.candidates = this.candidates.map((candidate) => {
          const item = data.find(it => it.carrierId == candidate.carrierId);
          if (item) {
            candidate.goHighWayLink = item.goHighWayLink;
            candidate.goHightWayStatus = item.goHightWayStatus;
            candidate.goHightWayClassifications = item.goHightWayClassifications;
          }
          return candidate;
        });

      },
      (err) => {}
    );
  }

  getCountCarriersForTabs() {
     this.api.GET(this.getCarrierCountApiByTab()).subscribe(
      res => {
        this.responsedCount = res.data?.responsedCount;
        this.noResponseCount = res.data?.noResponseCount;
        this.notSentCount = res.data?.notSentCount;
        this.sentButNoResponseCount = res.data?.sentButNoResponseCount;
      },
      error => {
        this.showErr(error);
      }
    )
  }

  onGetDataSucceeded(data) {
    const pools = this.bid?.pools || [];
    const result = data.map(item => {
      let pool = pools.find(it => it.id === item.poolId);
      let data = {};
      if (pool) data = { isDedicatedPool: pool.isDedicatedPool, basePrice: pool?.dedicatedInfo?.baseRate ?? item?.basePrice, poolName: pool?.name }
      const bidAnswers = this.getCounterBidHistory(item);
      const info = this.getSourceObj(item);
      const equipment = (item.fleets || []).filter(it => it?.vehicleType?.name).map(it => it?.vehicleType?.name).join(', ') || item?.equipment;
      const documentWarning = this.getDocumentWarning(item.documents);
      return {
        ...item,
        ...data,
        equipment,
        bidAnswers,
        source: info,
        documents: documentWarning.length ? { isPassed: false, messages: documentWarning } : { isPassed: true },
        rawSource: item.source,
        name: item?.name ?? item.metadata?.carrier?.name,
        expand: false,
      }
    })
    return result;
  }

  private getDocumentWarning(documents) {
    if (!Utils.isObjectNotEmpty(documents)) return [];
    const typeRequired = ['w9Document', 'autoInsurance', 'cargoInsurance']
    const insuranceRequired = ['autoInsurance', 'cargoInsurance']
    let arr = [];
    for (let key of typeRequired) {
      if (!Utils.isArrayNotEmpty(documents[key]?.uploadIds)) {
        arr.push({ status: 'Missing', label: this.getDocumentLabelByKey(key) });
      }
    }
    for (let key of insuranceRequired) {
      if (!documents[key]?.insurance?.startDate || !documents[key]?.insurance?.endDate) {
        arr.push({ status: 'Missing', label: `${this.getDocumentLabelByKey(key)} Date` });
      } else if (this.checkExpired(documents[key])) {
        arr.push({ status: 'Expired', label: `${this.getDocumentLabelByKey(key)} is expired` });
      }
    }
    return arr;
  }

  private getDocumentLabelByKey(key) {
    switch (key) {
      case 'w9Document': return 'W-9 Document';
      case 'autoInsurance': return 'Auto Insurance';
      case 'generalInsurance': return 'General Insurance';
      case 'cargoInsurance': return 'Cargo Insurance';
      case 'authorityDocument': return 'Authority Document Insurance';
    }
  }

  private checkExpired(item) {
    if (!item?.insurance?.endDate) {
      return false;
    }
    const now = new Date().toISOString();
    const endDate = new Date(item?.insurance?.endDate).toISOString();
    return endDate < now ? true : false;
  }

  onTabSelectedIndexChange(value: number) {
    this._selectedTabIndex = value;
    this.pageIndex = 1;
    this.onSetCarrierTabFilter();
  }

  private onSetCarrierTabFilter() {
    this.carrierSaleDetailService.setCarrierTabFilter({
      pageIndex: this.pageIndex,
      searchKeyword: this.searchKeyword,
      selectedTabIndex: this.selectedTabIndex,
      limit: this.limit,
    });
  }

  onChangePage(value: number) {
    this.pageIndex = value;
    this.onSetCarrierTabFilter();
  }

  get selectedTabIndex() {
    return this._selectedTabIndex;
  }

  get currentTabFilter(): TabFilter {
    switch (this._selectedTabIndex) {
      case 0: return TabFilter.responseReceived;
      case 1: return TabFilter.noRessponse;
      default: return TabFilter.responseReceived;
    }
  }

  public getTitleTab(tab: string): string {
    const tabs = {
      'response-received': `Response Received (${this.responsedCount})`,
      'no-response': `No response (${this?.noResponseCount})`
    }
    return tabs[tab];
  }

  get isActiveResponseReceivedTab(): boolean {
    return this.currentTabFilter === 'response-received';
  }

  get isActiveNoResponseTab(): boolean {
    return this.currentTabFilter === 'no-response';
  }

  private getApiByTab(tab: TabFilter) {
    const skip = this.pageIndex ? (this.pageIndex - 1) * this.limit : 0;
    return `${Const.APIV2(Const.APIURI_CARRIER_BIDS)}/${this.bid.id}/get_list_carriers?filter=${encodeURIComponent(JSON.stringify({ type: tab, search: this.searchKeyword }))}&skip=${skip}&limit=${this.isActiveResponseReceivedTab ? 100 : this.limit}`;
  }

  private getCarrierCountApiByTab() {
    return `${Const.APIV2(Const.APIURI_CARRIER_BIDS)}/${this.bid.id}/get-carrier-count-for-carrier-bid-detail?filter=${JSON.stringify({ search: this.searchKeyword })}`;
  }

  public searchKeyword: string = '';
  doSearch(value: string) {
    this.searchKeyword = value;
    this.pageIndex = 1;
    this.onSetCarrierTabFilter();
  }

  get localTimzone() {
    return Intl.DateTimeFormat().resolvedOptions().timeZone;
  }

  private getSourceObj = (item) => {
    let source = item?.metadata?.source;
    if (item.poolId) source = 'CARRIER_POOL';
    if (!source) return '-';
    let desc = '';
    let category = '';
    switch (source) {
      case 'GEO_FEATURE_ASSIGNED':
      case 'GEO_FEATURE_PLACED':
      case 'SAME_MARKET':
      case 'COVERAGE_AREA':
      case 'PREFERRED_LANE':
        category = 'WARP';
        desc = `Auto-added`;
        break;
      case 'EXTERNAL_MATCHING':
        category = this.capitalizeFirstLetter(source);
        desc = item?.metadata?.searching?.source ?? '';
        break;
      case 'CARRIER_POOL':
        category = 'WARP';
        desc = `CARRIER POOL`;
        break;
      case 'ASSIGNED_SAME_LANE':
        category = 'WARP';
        desc = `Assigned Same Lane`;
        break;
      case 'MANUAL':
        category = 'WARP';
        desc = item?.metadata?.addedBy?.name ? `${item?.metadata?.addedBy?.name} added` : '';
        break;
      case 'LOAD_BOARD_PUBLIC':
      case 'loadBoardPublic':
        category = 'Public Load Board';
        break;
      case 'loadBoard':
        category = 'Load Board';
        break;
      default:
        category = source;
    }
    return { category, desc }
  }

  public getCounterBidHistory(item) {
    let answers = item?.bidCounterOffer?.answers || [];
    if (item?.price && !item?.bidCounterOffer?.answers && !item?.bidCounterOffer?.answers.length) {
      answers.unshift({
        price: item.price,
        entity: item?.metadata?.entity ?? WarpConst.BidCounterOfferEntities.carrier,
        update: item?.bidBy || item?.update
      })
    }
    return answers;
  }

  openAddCarrierModal() {
    if(this.bid?.bidSessionIds?.length) return;

    DialogService.openFormDialog1(AddCarrier, {
      nzComponentParams: {
        model: this.bid,
        closeOnSuccess: true,
        updateSuccess: (resp) => {
          this.getListCarriers();
          this.getCountCarriersForTabs();
        },
      },
      nzClassName: "send-mail-form modal-xl",
    });
  }

  openAddCarrierPoolModal() {
    if(this.bid?.bidSessionIds?.length) return;

    DialogService.openFormDialog1(AddCarrierPool, {
      nzComponentParams: {
        model: this.bid,
        closeOnSuccess: true,
        updateSuccess: (resp) => {
          this.getListCarriers();
          this.getCountCarriersForTabs();
        },
      },
      nzClassName: "add-carrier-pool-form modal-xl",
    });
  }

  onAddCarriersByArea() {
    if(this.bid?.bidSessionIds?.length) return;

    DialogService.openDialog(AddCarrierByServiceArea, {
      nzComponentParams: {
        model: this.bid,
        onSave: this.addCarrierByCoverage.bind(this)
      },
      replaceWrapClassName: true,
      nzClosable: false,
      nzClassName: "add-carrier-pool-by-coverage modal-xxl",
    });
  }

  private addCarrierByCoverage(carrierIds: string[] = []) {
    let carriers = [];
    // const currentCarriers = this.bid?.metadata?.carriers || [];
    // for (let carrier of currentCarriers) {
    //   carriers.push({ carrierId: carrier?.carrierId })
    // }
    for (let carrierId of carrierIds) {
      let exist = carriers.find(it => it.carrierId === carrierId);
      if (!exist) carriers.push({ carrierId })
    }

    this.api.POST(`${Const.APIURI_CARRIER_BIDS}/${this.bid?.id}/update-carrier`, {
      carriers: carriers
    }).subscribe(
      (response) => {
        // this.onReload.emit();
        this.getListCarriers();
        this.getCountCarriersForTabs();
        const isWarning = carriers.length > 300;
        const ui_message = carriers.length > 300 ? "There are over 300 carriers on this bid. Please ensure all recipients are suitable to avoid excessive emails or SMS" : "Add Carriers successfully";
        if (isWarning) this.showWarning("", ui_message);
        else this.showInfo(ui_message);
      },
      (err) => {
        this.showErr(err);
      }
    );
  }

  onAddPoolsByArea() {
    if(this.bid?.bidSessionIds?.length) return;

    DialogService.openDialog(AddCarrierPoolByArea, {
      nzComponentParams: {
        model: this.bid,
        onSave: this.addCarrierByCoverage.bind(this),
      },
      nzClassName: "add-carrier-pool-by-coverage modal-xxl",
    });
  }

  get isDisableBtnAsap(): boolean {
    return !this.notSentCount || !!this.bid.bidSessionIds?.length;
  }

  get isDisableBtnSendRemind(): boolean {
    return !this.sentButNoResponseCount || !!this.bid.bidSessionIds?.length;
  }

  get isDisableBtnAddCarrier(): boolean {
    return !!this.bid.bidSessionIds?.length;
  }

  confirmSendAsap() {
    if (this.isOverPickupTime) {
      return this.openWarningOverPickupTime();
    }
    DialogService.openDialog1(SendASAPDlg, {
      nzComponentParams: {
        bidId: this.bid.id,
        type: 'sendASAP',
        onSave: () => this.api.POST(Const.APIV2(`${Const.APIURI_CARRIER_BIDS}/${this.bid.id}/update-queue`)),
        onDone: (data) => {
          this.onReload.emit();
          this.onTabSelectedIndexChange(1);
          this.showSuccess('Requeue successfull');
        }
      },
    });
  }

  confirmSendRemind() {
    if (this.isOverPickupTime) {
      return this.openWarningOverPickupTime();
    }
    DialogService.openDialog1(SendASAPDlg, {
      nzComponentParams: {
        bidId: this.bid.id,
        type: 'sendRemind',
        onSave: () => this.api.POST(Const.APIV2(`${Const.APIURI_CARRIER_BIDS}/${this.bid.id}/send-remind`)),
        onDone: (resp) => {
          this.onReload.emit();
          this.onTabSelectedIndexChange(1);
          this.showSuccess(resp);
        }
      },
    });
  }

  removeAllCandidate() {
    this.confirmDeletion({
      message: 'Are you sure you want to remove all candidates?',
      fnOk: () => {
        this.api.POST(Const.APIV2(`${Const.APIURI_CARRIER_BIDS}/${this.bid.id}/remove-all-candidates`)).subscribe(
          (response) => {
            this.onReload.emit();
            this.showSuccess("Remove all candidates successfully");
          },
          (err) => {
            this.showErr(err);
          }
        );
      }
    });
  }

  private openWarningOverPickupTime() {
    this.modalService.create({
      nzTitle: 'Update Pickup Time Required',
      nzContent: 'Pickup Time is over, please update the new pickup time then to continue bidding.',
      nzOkText: 'Go to update',
      nzWidth: '600px',
      nzOnOk: () => {
        this.carrierSaleDetailService.setOpenModalUpdatePickupTime(true);
      },
    });
  }

  performanceForCarrierList() {
    const url = `${Const.APIV2(Const.APIURI_CARRIER_BIDS)}/performance-for-carrier-list`;
    const bid = this.bid;
    const dropoffCode = bid?.dropoffAddress?.zipcode;
    const pickupCode = bid?.pickupAddress?.zipcode
    if (this.carrierIds?.length && dropoffCode && pickupCode) {
      return this.api.POST(url, { carrierIds: this.carrierIds, pickupCode, dropoffCode }).subscribe(
        (resp) => {
          const data = resp?.data?.list_data ?? [];
          this.candidates = this.candidates.map((candidate) => {
            const item = data.find(it => it.carrierId == candidate.carrierId);
            if (item) {
              candidate.countOfJob = item.countOfJob;
              candidate.countOfJobRateLike = item.countOfJobRateLike;
              candidate.countOfJobRateDislike = item.countOfJobRateDislike;
              candidate.lastCompleteJob = item.lastCompleteJob;
              candidate.lastCost = item.lastCost;
              candidate.countLane = item.countLane;
              candidate.countJobUnassigned = item.countJobUnassigned;
            }
            return candidate;
          });
        },
        (err) => {}
      );
    }
  }

  commlogHistoriesByCandidate() {
    const url = `${Const.APIV2(Const.APIURI_CARRIER_BIDS)}/commlog-histories-by-candidate`;

    if (this.bidCandidateIds?.length) {
      return this.api.POST(url, { bidCandidateIds: this.bidCandidateIds }).subscribe(
        (resp) => {
          const data = resp?.data?.list_data ?? [];
          this.candidates = this.candidates.map((candidate) => {
            const item = data.find(it => it.id == candidate.id);
            if (item) {
              candidate.lastSentSms = item.lastSentSms;
              candidate.lastSentEmail = item.lastSentEmail;
              candidate["commlogHistories"] = item.histories;
            }
            return candidate;
          });

        },
        (err) => {}
      );
    }
  }

  checkUnsubscribe() {
    const contactInfos = this.contactInfos;
    if (contactInfos?.length) {
      this.api
        .POST(
          Const.API_SERVICE_COMM_UNSUBSCRIBE + '/check-exist-by-contact-list',
          {
            contacts: this.contactInfos,
            scopes: ['carrier_bid'],
          }
        )
        .subscribe((res) => {
          if (res?.message === "Success") {
            this.contactInfos = res?.data?.list_data ?? [];
          }
        });
    }
  }

  reload() {
    this.onReload.emit();
  }
}
