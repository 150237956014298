<div class="carrier-selection">
  <div>
    <div class="group-header">
      <div class="text-header">Outreach</div>
      <ng-container *ngIf="shouldShowWarning">
        <div class="message-warning">
          This bid is being proceeded in bid session 
          <ng-container *ngFor="let bidSession of bidSessions">
            <a [routerLink]="[routeAdminBidSessions, bidSession.id]" target="_blank">{{ bidSession.name }}</a>
            <ng-container *ngIf="bidSessions.length > 1">,</ng-container>
          </ng-container>
          <br>
          You can remove bid from session to continue working on this bid.
        </div>
      </ng-container>
    </div>
    <div class="box-content top20">
      <div class="carrier-tab">
        <nz-tabset [(nzSelectedIndex)]="tabSelected" [nzType]="'line'" [nzSize]="'small'"
          (nzSelectedIndexChange)="onTabSelectedIndexChange()"
        >
          <nz-tab nzTitle="Invite Bid Automation"></nz-tab>
          <nz-tab nzTitle="External Posting"></nz-tab>
        </nz-tabset>
      </div>
      <ng-container *ngIf="isMatchedCarriersTab">
        <div>
          <carrier-sale-wave></carrier-sale-wave>
        </div>
      </ng-container>
      
      <ng-container *ngIf="isExternalPostingTab">
        <div>
          <list-external-posting></list-external-posting>
        </div>
      </ng-container>
     
    </div>
  </div>
</div>
