import to from 'await-to-js';
import { Component, OnInit } from '@angular/core';
import { Const } from '@const/Const';
import { BaseFormDialog1 } from '@dialogs/base-form-dlg1';
import _ from 'underscore'

interface DataCarrier {
  id: string,
  key: string,
  title: string,
  status: number,
  mc: string,
  dot: string,
  isSelected?: boolean,
  tag?: number,
}
type FnSubmit = (carrierIds: string[]) => Promise<any>

const MATCHING_TYPE = {
  0: {
    color: "warning",
    label: "Unmatched",
  },
  1: {
    color: "success",
    label: "Matched",
  },
};
@Component({
  selector: 'carrier-sale-add-carrier',
  templateUrl: './view.html',
  styleUrls: ['./style.scss']
})
export class AddCarrier extends BaseFormDialog1  implements OnInit {
  public isError = false;
  public isLoading = false;

  private originList = []
  private keyword
  
  private pool
  public poolSelected
  public pools = []
  private poolTags = {}
  public isLoadingPool

  public matchedCarriers: string[] = [];
  private matchedIds: string[] = [];
  public submit: FnSubmit | undefined;
  public currentCarrierIds: string[] | undefined;

  constructor() {
    super();
  }

  checked = false;
  loading = false;
  indeterminate = false;
  listOfData: readonly DataCarrier[] = [];
  listOfCurrentPageData: readonly any[] = [];
  setOfCheckedId = new Set<string>();

  updateCheckedSet(id: string, checked: boolean): void {
    if (checked) {
      this.setOfCheckedId.add(id);
    } else {
      this.setOfCheckedId.delete(id);
    }
  }

  onCurrentPageDataChange(listOfCurrentPageData: readonly any[]): void {
    this.listOfCurrentPageData = listOfCurrentPageData;
    this.refreshCheckedStatus();
  }

  refreshCheckedStatus(): void {
    const listOfEnabledData = this.listOfCurrentPageData.filter(({ disabled }) => !disabled);
    this.checked = listOfEnabledData.every(({ id }) => this.setOfCheckedId.has(id));
    this.indeterminate = listOfEnabledData.some(({ id }) => this.setOfCheckedId.has(id)) && !this.checked;
  }

  onItemChecked(id: string, checked: boolean): void {
    this.updateCheckedSet(id, checked);
    this.refreshCheckedStatus();
  }

  onAllChecked(checked: boolean): void {
    this.listOfCurrentPageData
      .filter(({ disabled }) => !disabled)
      .forEach(({ id }) => this.updateCheckedSet(id, checked));
    this.refreshCheckedStatus();
  }

  protected onUpdateSuccess(resp) {
    if (resp && resp.ui_message) {
      if(resp.isWarning) this.showWarning("", resp.ui_message);
      else this.showInfo(resp.ui_message);
    } else {
      this.showInfo("The item has been updated successfully.");
    }
    this.updateSuccess(resp);
    if (this.closeOnSuccess) {
      this.closeDialog();
    }
  }

  private async callApi(carriers: {carrierId: string}[]) {
    let err, resp;
    if (typeof this.submit == 'function') {
      [err, resp] = await to(this.submit(carriers.map(it => it.carrierId)));
    } else {
      [err, resp] = await to(this.api.POST(`${Const.APIURI_CARRIER_BIDS}/${this.model?.id}/update-carrier`, {carriers: carriers}).toPromise())
    }
    if (err) {
      this.showErr(err);
    } else if (resp) {
      resp.isWarning = carriers.length > 300;
      resp.ui_message = carriers.length > 300 ? "There are over 300 carriers on this bid. Please ensure all recipients are suitable to avoid excessive emails or SMS" : "Add carrier successfully";
      this.onUpdateSuccess(resp)
    }
    this.stopProgress();
  }

  async updateCarrier(): Promise<void> {
    this.loading = true;
    let carriers: {carrierId: string, isExist?: boolean}[] = []
    // const currentCarriers = this.model?.metadata?.carriers || []
    // for (let carrier of currentCarriers){
    //   carriers.push({ carrierId: carrier?.carrierId })
    // }
    const newCarriers = this.listOfData.filter(data => this.setOfCheckedId.has(data.id));
    for (let carrier of newCarriers){
      carriers.push({ carrierId: carrier?.id })
    }
    await this.callApi(carriers);
    setTimeout(() => {
      this.setOfCheckedId.clear();
      this.refreshCheckedStatus();
      this.loading = false;
    }, 1000);
  }

  ngOnInit(): void {
    this.onLoadData()
    this.onLoadPool()
  }


  private getCurrentCarriers(): string[] {
    if (this.currentCarrierIds) return this.currentCarrierIds;
    return (this.model?.metadata?.carriers || []).map(carrier => carrier.carrierId)
  }

  onLoadData() {
    this.isLoading = true;
    const currentCarrierIds: string[] = this.getCurrentCarriers(); //(this.model?.metadata?.carriers || []).map(carrier => carrier.carrierId)
    this.api.GET(`${Const.APIURI_CARRIERS}/list/all_for_bid`).subscribe((response) => {
      const carriers = _.get(response, ["data", "list_data"], []);
      let listCarriers: DataCarrier[] = []
      for (let carrier of carriers){
        let isSelected = false
        if (currentCarrierIds.includes(carrier.id)) isSelected = true //không cho chọn lại các carrier đã có ở list bên ngoài
        listCarriers.push({
          id: carrier.id,
          key: carrier.id,
          title: carrier.basicInfo?.name,
          status: carrier.status,
          mc:carrier.basicInfo?.mc,
          dot:carrier.basicInfo?.dot,
          isSelected: isSelected
        })
      }
      this.listOfData = listCarriers
      this.originList = [...this.listOfData];
      this.isLoading = false;

      this.fetchCarrierMatching()
    });
  }

  onLoadPool() {
    this.isLoadingPool = true;
    this.api.GET(Const.APIV2(`${Const.APIURI_POOLS}?&limit=-1&filter=${JSON.stringify({subjectType: 'carrier'})}`)).subscribe((response) => {
      const pools = _.get(response, ["data", "list_data"], []);
      this.pools = pools;
      let allCarrierIds = []
      for(let pool of pools) {
        if(pool.subjectIds.length) allCarrierIds = [...allCarrierIds, ...pool.subjectIds]
      }
      let poolTags = {};
      for(let carrierId of allCarrierIds) {
        let result = this.pools.filter(it => it.subjectIds.includes(carrierId));
        poolTags[carrierId] = result.map(it => it.name);
      }
      this.poolTags = poolTags;
      this.isLoadingPool = false;
    });
  }

  getPoolTags(id) {
    const tags = this.poolTags[id] || [];
    return tags;
  }
  onPoolChange(value) {
    const pool = this.pools.find(it => it.id === value);
    const { subjectIds = [] } = pool || {};
    this.pool = subjectIds;
    this.onFilterData();
  }
  onFilterData() {
    if (!this.keyword) {
      this.listOfData = this.originList
      this.updateCarrierList(); //khi keyword = "" => cần sắp xếp lại carrier list như ban đầu
      return
    }
    this.listOfData = this.originList.filter(it => this.filterCarrier(this.keyword, it) && (!this.pool?.length || this.pool?.includes(it.key)));
  }

  filterCarrier(inputValue: string, item: any): boolean {
    if(!inputValue) return true;
    const regexName = new RegExp(inputValue, "i");
    const regexMc = new RegExp(inputValue, "i");
    const regexDot = new RegExp(inputValue, "i");
    return regexName.test(item.title) || regexMc.test(item.mc) || regexDot.test(item.dot);
  }

  onSearch(value) {
    this.keyword = value;
    this.onFilterData();
  }

  $asCarrierStatusText = (status) => {
    function capitalizeFirstLetter(string) {
      return string.charAt(0).toUpperCase() + string.slice(1);
    }

    const statusKey = Object.keys(Const.CarrierStatus).filter((key) => Const.CarrierStatus[key] == status)[0] || "";
    return capitalizeFirstLetter(statusKey);
  };

  fetchCarrierMatching({autoSelected} = {autoSelected: true}) {
    const pickupAddress = this.model?.pickupAddress;
    const dropoffAddress = this.model?.dropoffAddress;

    if (!pickupAddress || !dropoffAddress || !pickupAddress.zipcode || !dropoffAddress.zipcode) return;
    this.isLoading = true;
    this.api
      .POST(`${Const.APIURI_CARRIERS}/get_candidates_for_bid`, {
        addresses: [pickupAddress, dropoffAddress],
        equipments: this.model.equipments
      })
      .subscribe(
        (response) => {
          const matchedCarriers = _.get(response, ["data", "list_data"], []);
          this.matchedCarriers = matchedCarriers.map((carrier) => carrier.id);
          this.matchedIds = matchedCarriers;
          this.updateCarrierList({ updateMatchedIds: true });
          this.isLoading = false;
        },
        (err) => {
          this.updateCarrierList({ updateMatchedIds: false });
          this.matchedCarriers = [];
          this.isLoading = false;
        }
      );
  }

  $asCarrierMatchingType = (matchingTypeNumber: number) => {
    //matchingTypeNumber !=-1 là có index trong matching. tức là có match. index ==-1 là không match
    return MATCHING_TYPE[matchingTypeNumber != -1 ? 1 : 0];
  };

  updateCarrierList({ updateMatchedIds } = { updateMatchedIds: false }) {
    this.listOfData.map((item) => {
      item.tag = this.matchedIds.indexOf(item.key);
    });

    //sort by tag desc, status desc, title asc
    this.listOfData = _(this.listOfData)
      .chain()
      .sortBy(function (patient) {
        return patient.title;
      })
      .sortBy(function (patient) {
        let status = 0;
        switch (patient.status) {
          case Const.CarrierStatus.active: return 0;
          case Const.CarrierStatus.temporary: return 1;
          case Const.CarrierStatus.pending: return 2;
          case Const.CarrierStatus.rejected: return 3;
          default: return 9
        }
      })
      .sortBy(function (patient) {
        return patient.tag * -1; //reverse sort => sort tag desc
      })
      .value();    
    // this.autoSelectMatchedCarrier() //17-07-2023: cái này nếu cần thì enable lên sau
  }

  autoSelectMatchedCarrier(){
    for (let id of this.matchedIds){
      const isExistInTable = this.listOfData.find(carrier => carrier.id == id)
      if (isExistInTable) this.onItemChecked(id, true)
    }
  }
}
