<div nz-form [formGroup]="formInput">
  <ng-container *ngFor="let key of keys">
    <div *ngIf="getLabel(key)" class="field-label">
      {{getLabel(key)}}
      <span *ngIf="isRequired(key)" class="label-mark-required"></span>
    </div>
    <nz-form-item>
      <nz-form-control>
        <ng-container [ngSwitch]="key">

          <ng-container *ngSwitchCase="'timeFrame'">
            <div [formGroupName]="key" [ngClass]="key">
              <div *ngIf="'range' as subKey">
                <div class="field-label">{{getLabel(fullKey(key, subKey))}}<span *ngIf="isRequired(fullKey(key, subKey))" class="label-mark-required"></span></div>
                <nz-form-item>
                  <nz-form-control>
                    <nz-range-picker
                      [nzFormat]="'yyyy-MM-dd'"
                      [nzShowToday]="true"
                      [nzPlaceHolder]="getPlaceHolder(fullKey(key, subKey))"
                      [formControlName]="subKey"
                      (ngModelChange)="onValueChange(fullKey(key, subKey), $event)"
                    ></nz-range-picker>
                  </nz-form-control>
                </nz-form-item>
              </div>
              <div *ngIf="'timezone' as subKey">
                <div class="field-label">{{getLabel(fullKey(key, subKey))}}<span *ngIf="isRequired(fullKey(key, subKey))" class="label-mark-required"></span></div>
                <nz-form-item>
                  <nz-form-control>
                    <nz-select style="width: 100%; min-width: 200px;"
                      [formControlName]="subKey"
                      (ngModelChange)="onValueChange(fullKey(key, subKey), $event)">
                      <nz-option 
                        *ngFor="let it of timezones" 
                        [nzLabel]="timezoneLabel(it)" 
                        [nzValue]="it.shortName"
                      >
                      </nz-option>
                    </nz-select>
                  </nz-form-control>
                </nz-form-item>
              </div>
            </div>
          </ng-container>

          <select-by-searching *ngSwitchCase="'clientIds'"
            [version]="2"
            type="client"
            [nzMode]="'multiple'"
            [getAllAtOnce]="isGetAllAtOnce"
            [formControlName]="key"
            [(listData)]="listClients"
            [showSubClient]="true"
            [placeholder]="getPlaceHolder(key)"
            (ngModelChange)="onValueChange(key, $event)">
          </select-by-searching>

          <nz-select *ngSwitchCase="'markets'"
            nzBackdrop="true"
            nzAllowClear
            nzShowSearch
            [nzMode]="'multiple'"
            [formControlName]="key"
            [nzLoading]="isLoadingMarket"
            [nzPlaceHolder]="getPlaceHolder(key)"
            (ngModelChange)="onValueChange(key, $event)"
            style="width: 100%">
            <nz-option *ngFor="let item of listMarkets" [nzValue]="item" [nzLabel]="item">
            </nz-option>
          </nz-select>

          <div *ngSwitchCase="'vehicleTypes'"
            vehicle-selector
            [mode]="'multiple'"
            [placeholder]="getPlaceHolder(key)"
            [isDisabled]="isReadOnly(key)"
            [value]="getVehicleType()"
            (valueChange)="onValueChange(key, $event)">
          </div>

          <ng-container *ngSwitchDefault>
            <input nz-input *ngIf="!isMultiline(key)"
              [formControlName]="key"
              [type]="getInputType(key)"
              [placeholder]="getPlaceHolder(key)"
              (input)="onInputChanged($event, key)">
            <textarea nz-input *ngIf="isMultiline(key)"
              [formControlName]="key"
              [placeholder]="getPlaceHolder(key)"
              [nzAutosize]="{ minRows: 2, maxRows: 3 }"
              (input)="onInputChanged($event, key)"></textarea>
          </ng-container>
        </ng-container>
      </nz-form-control>
    </nz-form-item>
  </ng-container>
</div>