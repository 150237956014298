<td>
  <div class="g-session-item">
    <div class="session-section g-name">
      <div class="g-title">
        <div style="line-height: 26px;">
          <a class="title clickable" [routerLink]="detailRouterLink">{{ item.name }}</a>
          <span nz-icon nzType="right" nzTheme="outline" class="left10 clickable" (click)="onToggleContent()" [nzRotate]="isToggle ? 90 : 0"></span>
        </div>
        <div *ngIf="item.descShort" style="line-height: 26px;">{{item.descShort}}</div>
        <div class="content" style="line-height: 26px;">
          {{ displayTimeWindow() }}
        </div>
      </div>
    </div>

    <div class="session-section g-route">
      <div class="title">
        {{ item.routeIds?.length }} Routes
      </div>
      <div class="content">
        {{ countAssigned }} assigned | {{ countHasBids }} has bids | {{ countNoBid }} no bid
      </div>
    </div>

    <div class="session-section">
      <div class="text">
        Carrier Added: {{ countCandidate }}<br />
        Invitation Sent to {{ countSentCandidate }} carriers
      </div>
    </div>

    <div class="session-section">
      <div>
        Created by <a [routerLink]="routerLink_createdUser" target="_blank">{{item.created.by.name || 'System'}}</a>
      </div>
      <span class="created-when">{{createdTime}}</span>
    </div>

    <div class="session-section g-action">
      <button nz-button nzType="text" nzDanger (click)="onBtnDeleteItem(item)" [nzLoading]="item.isLoading">
        <i nz-icon nzType="delete" nzTheme="outline" nz-tooltip></i>
      </button>
      
      <!-- <div class="text clickable" (click)="onAddRoute()">
        Add Route
      </div> -->
      <!-- <div class="text">
        Setting
      </div> -->
    </div>
  </div>

  <div class="g-session-content" *ngIf="isToggle">
    <ng-container *ngFor="let job of item.routes">
      <div class="g-content-item">
        <div class="route-info">
          <a [routerLink]="[routeAdminDispatchList, job.id]" target="__blank">
            Route: {{ job?.code }}
          </a>
        </div>
      </div>
    </ng-container>

    <i *ngIf="!item.routeIds?.length" class="grey">No route added</i>
  </div>
</td>