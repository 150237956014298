import {
  RequestBidSessionCreate,
  ResponseBidSessionDetail,
  RequestBidSessionUpdate,
} from "@wearewarp/types-server-admin/carrier-bid/bid-session";
import { FormDataBidSessionBasicInfo } from "./interface";
import { DateUtil } from "@services/date-utils";
import { Utils } from "@services/utils";

function fromDataToModel_timeFrame(timeFrame: {range: Date[], timezone: string} | undefined) {
  const range = timeFrame?.range ?? [];
  let _fromTime = range[0];
  let _toTime = range?.[range.length - 1];
  let from: string = '';
  let to: string = '';
  const timezone = DateUtil.mapTimezoneUS(<any>timeFrame?.timezone, '');
  if (_fromTime) {
    if (timezone) {
      _fromTime = DateUtil.toBeginOfDay(_fromTime);
    }
    from = DateUtil.convertLocalTime(_fromTime, timezone).toISOString();
  }
  if (_toTime) {
    if (timezone) {
      _toTime = DateUtil.toEndOfDay(_toTime);
    }
    to = DateUtil.convertLocalTime(_toTime, timezone).toISOString();
  }
  return {from, to, timezone};
}

export function formDataToRequestCreate(formData: FormDataBidSessionBasicInfo): RequestBidSessionCreate {
  const timeFrame = fromDataToModel_timeFrame(formData.timeFrame);
  const requestData: RequestBidSessionCreate = {
    name: formData.name,
    descShort: formData.descShort,
    descLong: formData.descLong,
    fromTime: timeFrame.from,
    toTime: timeFrame.to,
    timeZone: timeFrame.timezone,
  };
  if (Utils.isArrayNotEmpty(formData.clientIds)) {
    requestData.clientIds = formData.clientIds
  }
  if (Utils.isArrayNotEmpty(formData.markets)) {
    requestData.markets = formData.markets
  }
  if (Utils.isArrayNotEmpty(formData.vehicleTypes)) {
    requestData.vehicleTypes = formData.vehicleTypes
  }
  return requestData;
}

export function formDataToRequestUpdate(formData: Partial<FormDataBidSessionBasicInfo>): RequestBidSessionUpdate {
  const requestData: RequestBidSessionUpdate = {};
  if (formData.name !== undefined) {
    requestData.name = formData.name;
  }
  if (formData.descShort !== undefined) {
    requestData.descShort = formData.descShort;
  }
  if (formData.descLong !== undefined) {
    requestData.descLong = formData.descLong;
  }
  if (formData.clientIds !== undefined) {
    requestData.clientIds = formData.clientIds;
  }
  if (formData.markets !== undefined) {
    requestData.markets = formData.markets;
  }
  if (formData.vehicleTypes !== undefined) {
    requestData.vehicleTypes = formData.vehicleTypes;
  }
  if (formData.timeFrame !== undefined) {
    const timeFrame = fromDataToModel_timeFrame(formData.timeFrame);
    requestData.fromTime = timeFrame.from;
    requestData.toTime = timeFrame.to;
    requestData.timeZone = timeFrame.timezone;
  }
  return requestData;
}

export function detailToFormData(detail: ResponseBidSessionDetail): FormDataBidSessionBasicInfo {
  const tzObj = DateUtil.timezoneObjectFromFullName(detail.timeZone);
  const formData: FormDataBidSessionBasicInfo = {
    name: detail.name,
    descShort: detail.descShort,
    descLong: detail.descLong,
    timeFrame: {
      range: [,],
      timezone: tzObj.shortName,
    },
  }
  if (detail.fromTime) {
    formData.timeFrame.range[0] = DateUtil.convertLocalTime2(detail.fromTime, detail.timeZone);
  }
  if (detail.toTime) {
    formData.timeFrame.range[1] = DateUtil.convertLocalTime2(detail.toTime, detail.timeZone);
  }
  if (detail.clients) {
    formData.clientIds = detail.clients.map(it => it.id);
  }
  if (detail.vehicleTypes) {
    formData.vehicleTypes = detail.vehicleTypes.map(it => it.code)
  }
  if (detail.markets) {
    formData.markets = detail.markets;
  }
  return formData;
}
