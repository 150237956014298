import { Component } from "@angular/core";
import { Const } from "@const/Const";
import { getDashboard } from "@services/index";
import { BaseList } from "../../base/list";
import { ModalHelper } from "@wearewarp/ng-antd";
import { BidSessionBasicInfo } from "../form-basic-info";
import { UIHelper } from "@services/UIHelper";
import { FormDataBidSessionBasicInfo } from "../form-basic-info/interface";
import { RequestBidSessionCreate, ResponseBidSessionListItem } from "@wearewarp/types-server-admin/carrier-bid/bid-session";
import { formDataToRequestCreate } from "../form-basic-info/helper";
import { DataRepoBidSession } from "@app/data-repo/bid-session";

interface TabData {
  key: string, 
  name: string, 
  query: any, 
  total: number, 
  hidden?: boolean
}
@Component({
  selector: 'bid-sessions-list',
  templateUrl: './index.html',
  styleUrls: [
    './style.scss',
    '../../list.scss'
  ]
})
export class BidSessionListComponent extends BaseList<ResponseBidSessionListItem> {
  tabIndex = -1;
  public qParams: any;

  tabs: TabData[] = [
    {
      key: 'open',
      name: 'Bidding Session',
      query: {},
      total: 0,
    },
    {
      key: 'over_due',
      name: 'Over Due',
      query: {},
      total: 0,
    }
  ];
  
  get tabList() {
    return this.tabs.filter(it => !it.hidden);
  }

  constructor(
    private modalHelper: ModalHelper,
    private repoBidSession: DataRepoBidSession,
  ) {
    super();
  }

  onTabChange(event) {
    let tab = this.getTabData(event.index)
    this.paginationData.reset();
    this.tabIndex = event.index;
    const q: any = { bidStage: tab?.key, page: 1 }
    const search = this.queryParams.search ?? '';
    if (search) {
      q.search = search;
    }
    let currentFilter = JSON.parse(this.queryParams.filter);
    currentFilter.bidStage = tab?.key;
    q['filter'] = JSON.stringify(currentFilter);
    this.routeWithQueryUrl(q, true);
  }

  private getTabData(index: number): TabData {
    if (index >= 0 && index < this.tabList.length) {
      return this.tabList[index];
    }
    return this.tabList[0];
  }

  ngOnInit(): void {
    super.ngOnInit();
    setTimeout(() => getDashboard().sideBar.isSmallWidth = true, 1);
  }

  getDefaultFilter = () => {
    return { bidStage: 'open' }
  }

  onBtnCreate() {
    this.modalHelper.openForm(BidSessionBasicInfo, {
      nzTitle: 'Create new bid session',
      nzClassName: 'modal-create-bid-session',
      nzOkText: 'Create',
      onSubmitError: err => UIHelper.showErr(err),
      onSubmitSucceeded: resp => this.onCreateSuccess(),
      nzComponentParams: {
        submit: data => this.create(data)
      }
    });
  }

  onItemDeleted(item: ResponseBidSessionListItem) {
    this.onBtnRefresh();
  }

  private create(formData: FormDataBidSessionBasicInfo) {
    const requestData: RequestBidSessionCreate = formDataToRequestCreate(formData);
    return this.repoBidSession.createSession(requestData);
  }

  private onCreateSuccess() {
    this.router.navigate([Const.routeAdminBidSessions]);
  }

  protected handleNavigationEnd(url: string, prevQueryParam: any): void {
    const { bidStage } = this.queryParams;
    for (let index = 0; index < this.tabList.length; index++) {
      if (this.tabList[index].key === bidStage) {
        this.tabIndex = index
      }
    }

    if (!bidStage && !this.queryParams.filter && !this.queryParams.search) {
      let filter = JSON.stringify(this.getDefaultFilter());
      return this.routeWithQueryUrl({ filter });
    }
    //nếu đổi filter thì reset lại list job
    if (JSON.stringify(prevQueryParam?.filter) != JSON.stringify(this.queryParams.filter)) {
      this.paginationData.reset()
    }
    super.handleNavigationEnd(url, prevQueryParam);
  }

  public get queryParams() {
    return super.queryParams
  }

  protected getApiUrl(): string {
    return Const.APIURI_BID_SESSIONS;
  }

  protected onGetDataSucceeded(resp) {
    this.tabs = this.tabs.map(it => {
      it.total = resp?.data?.summary?.[it.key] || 0;
      return it
    });

    this.qParams = this.queryParams
  }

  onSearch(data = {}) {
    let tab = this.getTabData(this.tabIndex)
    const q: any = { bidStage: tab?.key, page: 1 }
    const search = this.queryParams.search ?? '';
    if (search) {
      q.search = search;
    }
    q['filter'] = JSON.stringify({ bidStage: tab?.key, ...data })
    this.routeWithQueryUrl(q, true);
  }

  inputSearchEnter(event: KeyboardEvent) {
    const keyword = (<HTMLInputElement>event.target)?.value?.trim() ?? '';
    this.doSearch(keyword);
  }
}
