import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { NzTableModule } from 'ng-zorro-antd/table';
import { NzDropDownModule } from 'ng-zorro-antd/dropdown';
import { RouterModule } from '@angular/router';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzTagModule } from 'ng-zorro-antd/tag';
import { NzToolTipModule } from 'ng-zorro-antd/tooltip';
import { NzGridModule } from 'ng-zorro-antd/grid';
import { SearchBoxModule } from '@libs/search-box/search-box.module';
import { NzBadgeModule } from 'ng-zorro-antd/badge';
import { NzDividerModule } from 'ng-zorro-antd/divider';
import { BidSessionCarriers } from '.';
import { ContactInfoModule } from '@app/admin/components/contact-info/module';

@NgModule({
  imports: [
    CommonModule,
    NzIconModule,
    NzTableModule,
    NzDropDownModule,
    NzButtonModule,
    RouterModule,
    NzTagModule,
    SearchBoxModule,
    NzToolTipModule,
    NzDividerModule,
    NzGridModule,
    NzBadgeModule,
    ContactInfoModule
  ],
  declarations: [
    BidSessionCarriers
  ],
  exports: [
    BidSessionCarriers
  ],
  providers: [
  ]
})
export class BidSessionCarriersModule { }