import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzCollapseModule } from 'ng-zorro-antd/collapse';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { NzTagModule } from 'ng-zorro-antd/tag';
import { NzPaginationModule } from 'ng-zorro-antd/pagination';
import { BidSessionDetailComponent } from '.';
import { BidSessionOutreachModule } from '../component/outreach/module';
import { BidSessionCarriersModule } from '../component/carriers/module';
import { ModuleBidSessionBasicInfo } from '../form-basic-info/module';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    NzButtonModule,
    NzCollapseModule,
    NzIconModule,
    NzPaginationModule,
    NzTagModule,
    ModuleBidSessionBasicInfo,
    BidSessionOutreachModule,
    BidSessionCarriersModule,
  ],
  declarations: [
    BidSessionDetailComponent
  ],
  exports: [
    BidSessionDetailComponent
  ],
  providers: [
  ]
})
export class ModuleBidSessionDetail { }