import { CarrierSaleDetailService } from './../../../carrier-sale-detail.service';
import { Component } from "@angular/core";

import { BaseComponent } from "@abstract/BaseComponent";
import { DialogService } from "@dialogs/dialog.service";
import { AddCarrierSaleRep } from "@app/admin/dispatch/components/sales/add-carrier-sale-rep";
import { Subscription } from 'rxjs';
import { BizUtil } from '@services/biz';
import { Utils } from '@services/utils';
import { InputHelper } from '@services/input-helper';
import { Const as WarpConst } from "@wearewarp/universal-libs";
import { Const } from '@const/Const';
import { BookAppointment, FormDataUpdateTimeWindows, ModalHelper, UpdateTimeWindows } from '@wearewarp/ng-antd';
import { MasterData } from '@services/master.data';
import { JobUpdateFormId } from '@wearewarp/types-server-admin/form-data/dispatch';
import { BasicRouteInfo } from '@app/admin/dispatch/components/basic-route-info';

@Component({
  selector: "carrier-sale-route-info",
  templateUrl: "./index.html",
  styleUrls: ["./index.scss"],
})
export class CarrierSaleRouteInfo extends BaseComponent {

  bid: any;
  jobHyperLink;
  routeInfo: any = {};
  remainingTime: any = {};
  timer = null;
  isShowCarrierSalesLegacy = false;
  private bidDetailSubscription: Subscription;
  private openModalUpdatePickupTimeSubscription: Subscription;
  
  constructor(private carrierSaleDetailService: CarrierSaleDetailService, private modalHelper: ModalHelper,) {
    super();
  }

  ngOnInit() {
    this.bidDetailSubscription = this.carrierSaleDetailService.bidDetail$.subscribe(bidDetail => {
      this.bid = bidDetail;
      this.onProcessRouteInfo(bidDetail);
    });
    this.openModalUpdatePickupTimeSubscription = this.carrierSaleDetailService.isOpenModalUpdatePickupTime$.subscribe(value => {
      if (value === true) {
        this.onBtnUpdatePickupTime();
      }
    });
    this.loadConfig();
  }

  showRouteBasicInfo(jobId: string) {
    this.modalHelper.open(BasicRouteInfo, {nzComponentParams: {jobId}});
  }
 
  calculateRemainingTime() {
    if (!this.bid?.pickupDate) return;
    const currentTime = new Date();
    const difference = new Date(this.bid.pickupDate).getTime() - currentTime.getTime();
    this.remainingTime.isNegative = difference < 0;
    const absoluteDifference = Math.abs(difference);
    this.remainingTime.days = Math.floor(absoluteDifference / (1000 * 60 * 60 * 24));
    this.remainingTime.hours = Math.floor((absoluteDifference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
    this.remainingTime.minutes = Math.floor((absoluteDifference % (1000 * 60 * 60)) / (1000 * 60));
    this.remainingTime.seconds = Math.floor((absoluteDifference % (1000 * 60)) / 1000);
    this.carrierSaleDetailService.setOverPickupTime(this.remainingTime.isNegative);
  }

  private getMileage(): string {
    const totalDistance = this.bid?.job?.totalDistance
    if (!totalDistance) return '';
    return (totalDistance / 1609.34).toFixed(2).toLocaleString();
  }

  private getVehicles(vehicles): any[] {
    if (!vehicles?.length) return [];
    vehicles = vehicles.filter(x => x);
    return vehicles.map(vehicle => {
      if (vehicle.options?.length) {
        return `${vehicle.name} /w ${vehicle.options.map(Utils.capitalize).join(", ")}`
      }
      return vehicle.name
    });
  }


  public getFirstCommodity() {
    if (this.bid?.job?.totalShipmentsItems?.commodity?.length) {
      return this.bid.job.totalShipmentsItems.commodity[0];
    }
    else return "";
  }

  public getAllCommodities() {
    return this.bid?.job?.totalShipmentsItems?.commodity?.join(', ');
  }

  private isShowRemainingTimePickup() {
    if (!this.bid?.pickupDate) return false;
    return true;
  }


  private getTotalShipmentCostPerMile() {
    let mileage: any = this.getMileage();
    if (!mileage) return null;
    mileage = parseFloat(mileage);
    const totalShipmentCost = InputHelper.getValueMoney(this.bid?.job?.totalShipmentsCost)
    if (mileage && Utils.isNumber(totalShipmentCost)) {
      return `$${(totalShipmentCost / mileage).toFixed(2)}/mi`;
    }
    return null;
  }

  getLabelType(key) {
    switch (key) {
      case Const.CarrierBidTypes.direct: return 'Auto assign when carrier accept load (Direct)';
      case Const.CarrierBidTypes.manual: return 'Manual review (Indirect)';
      default: return 'Manual';
    }
  }


  private onProcessRouteInfo(bidDetail) {
    if (bidDetail.job) this.jobHyperLink = BizUtil.createHyperLinkForJob(bidDetail.job);
    this.routeInfo = {
      isGhostLoad: bidDetail?.job?.type === WarpConst.JobType.ghost,
      isMarketplace: bidDetail?.job?.type === WarpConst.JobType.ghost && bidDetail.job?.source == WarpConst.JobSources.marketplace,
      isLinehaul: bidDetail?.job?.type === WarpConst.JobType.linehaul,
      clientName: bidDetail?.job?.clients?.[0]?.name || 'N/A',
      numOfClient: bidDetail?.job?.clients?.length ?? 0,
      arrClientName: bidDetail?.job?.clients?.map(it => it.name) || [],
      mileage: this.getMileage(),
      carrierSalesRep: this.getFullName(bidDetail?.job?.carrierSalesRep),
      isShowRemainingTimePickup: this.isShowRemainingTimePickup(),
      vehicles: this.getVehicles(bidDetail?.vehicles),
      firstCommodity: this.getFirstCommodity(),
      allCommodities: this.getAllCommodities(),
      tempRange: bidDetail?.job?.tempRange,
      numOfServiceOptions: bidDetail?.serviceOptions?.length ?? 0,
      firstServiceOptions: bidDetail?.serviceOptions?.[0]?.name ?? '',
      allServiceOptions: bidDetail?.serviceOptions?.map(it => it.name)?.join(', '),
      numOfOptionalEquipments: bidDetail?.optionalEquipments?.length ?? 0,
      firstOptionalEquipments: bidDetail?.optionalEquipments?.[0]?.label ?? '',
      allOptionalEquipments: bidDetail?.optionalEquipments?.map(it => it.label)?.join(', '),
      totalShipmentCost: bidDetail?.job?.totalShipmentsCost,
      totalShipmentCostPerMile: this.getTotalShipmentCostPerMile(),
      bidModeType: this.getLabelType(bidDetail?.type),
      warpOffer: Utils.isNumber(bidDetail?.basePrice) ? InputHelper.formatMoney2(String(bidDetail.basePrice)) : null,
    }
    if (this.routeInfo.isShowRemainingTimePickup) {
      if (this.timer) clearInterval(this.timer);
      this.calculateRemainingTime();
      this.timer = setInterval(() => {
        this.calculateRemainingTime();
      }, 1000);
    }
  }

  onBtnEditCarrierSalesRep() {
    if (!this.bid?.jobId) return;
    const carrierSalesRepId = this.bid?.job?.carrierSalesRep?.id;
    DialogService.openFormDialog1(AddCarrierSaleRep, {
      nzComponentParams: {
        jobId: this.bid.jobId,
        carrierSalesRepId: carrierSalesRepId,
        closeOnSuccess: true,
        updateSuccess: resp => {
          this.carrierSaleDetailService.emitGetDataBid();
        }
      },
      nzClassName: 'modal-no-padding',
    })
  }

  ngOnDestroy() {
    this.timer && clearInterval(this.timer)
    if (this.bidDetailSubscription) {
      this.bidDetailSubscription.unsubscribe();
    }
    if (this.openModalUpdatePickupTimeSubscription) {
      this.openModalUpdatePickupTimeSubscription.unsubscribe();
    }
  }

  gotoLegacyBid() {
    return [`${this.routeDashboard}/carrier-sales/carrier-sales-legacy`, this.bid.id];
  }

  onBtnUpdatePickupTime() {
    const stops = this.bid?.job?.stops ?? [];
    const firstPickup = stops?.filter(x => x.type == Const.TaskType.PICKUP)?.[0];
    if (!firstPickup) {
      this.showErr('No found pickup task');
      return;
    }
    const appointmentFrom = firstPickup.info?.appointmentInfo?.from;
    if (!appointmentFrom) {
      UpdateTimeWindows.openModal(this.modalHelper, {
        onSubmitError: err => this.showErr(err),
        onSubmitSucceeded: resp => setTimeout(() => this.carrierSaleDetailService.emitGetDataBid(), 1000),
        nzTitle: `Update Pickup Time Windows`,
        nzComponentParams: {
          timezone: firstPickup?.info?.addr?.metadata?.timeZoneStandard,
          reasonCodes: MasterData.getChangeDateTimeReasons(),
          model: {
            windows: firstPickup?.info?.windows,
          },
          submit: (data: FormDataUpdateTimeWindows) => this.updateJobPickupTime('location-windows', firstPickup, data)
        }
      });
    } else {
      BookAppointment.openModal(this.modalHelper, {
        onSubmitError: err => this.showErr(err),
        onSubmitSucceeded: resp => setTimeout(() => this.carrierSaleDetailService.emitGetDataBid(), 1000),
        nzTitle: `Update Pickup Appointment`,
        nzComponentParams: {
          timezone: firstPickup?.info?.addr?.metadata?.timeZoneStandard,
          reasonCodes: MasterData.getChangeDateTimeReasons(),
          model: {
            appointmentInfo: firstPickup.info.appointmentInfo,
          },
          submit: data => this.updateJobPickupTime('location-appointment', firstPickup, data),
        }
      });
    }
  }

  private updateJobPickupTime(formId: JobUpdateFormId, stop: any, data: any) {
    const allShipments = this.bid?.job?.shipments ?? [];
    const shipmentIds = stop.shipments?.map(x => x.id)?.filter(x=>x) ?? [];
    const shipments = allShipments.filter(x => shipmentIds.includes(x.id));
    const pickInfoIds = shipments.map(sh => sh.deliveryInfos?.find(item => item.type == Const.TaskType.PICKUP)?.id)?.filter(x=>x);
    const url = Const.APIV2(`${Const.APIURI_JOBS}/${this.bid?.jobId}/${formId}`);
    const params = {
      ...data,
      locationType: stop.type,
      deliveryIds: pickInfoIds,
      shipmentIds: shipmentIds,
      taskIds: stop.taskIds,
    }
    return this.api.PUT(url, params);
  }

  private loadConfig() {
    if (Const.SHOW_CARRIER_SALES_LEGACY) {
      this.isShowCarrierSalesLegacy = true;
      return;
    }
    const url = `${Const.APIV2(`metadata`)}/USER_${this.authUser.id}/USER_PREFERENCES/CARRIER_SALES_LEGACY`
    this.api.GET(url).subscribe((res) => {
      if (res?.value === 'true' || res?.value === true) {
        this.isShowCarrierSalesLegacy = true;
      }
    });
  }

  public getRouteStatus() {
    const status = this.bid?.job?.status;
    if (status === Const.JobStatus.completed) {
      return 'Completed';
    }
    if (status === Const.JobStatus.canceled) {
      return 'Cancelled';
    }
    if (status === Const.JobStatus.inProgress) {
      return 'In Progress';
    }
    return 'Created';
  }
}