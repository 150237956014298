<div class="text-header">Equipment</div>
<div class="line-item equipment-info-item">
  <div class="label">Load</div>
  <div class="value">
    <ng-container *ngIf="displayInfo.vehicles?.length">
      <div *ngFor="let vehicle of displayInfo.vehicles">{{ vehicle }}</div>
    </ng-container>
    <ng-container *ngIf="!displayInfo.vehicles?.length">
      <div>N/A</div>
    </ng-container>
  </div>
</div>
<div class="line-item equipment-info-item">
  <div class="label">Items</div>
  <div class="value">
    {{bid.job?.totalShipmentsItems?.weight}}
    <ng-container *ngIf="bid?.job?.totalShipmentsItems?.units?.length">,
      {{bid.job.totalShipmentsItems.units}}
    </ng-container>

    <code style="color: #888; margin-left: 3px;" 
      class="clickable" nz-popover
      nzPopoverTitle="Truck Load Evolution" 
      nzPopoverTrigger="click" 
      [nzPopoverContent]="contentTemplate"
      nzPopoverPlacement="bottomLeft"
    >
      <i nz-icon nzType="info-circle" nzTheme="outline"></i>
    </code>
    <ng-template #contentTemplate>
      <div truck-load-evolution [jobId]="bid?.job?.id"></div>
    </ng-template>
  </div>
</div>
<div *ngIf="displayInfo.tempRange" class="line-item equipment-info-item">
  <div class="label">Temperature</div>
  <div class="value">{{ displayInfo.tempRange }}</div>
</div>
<div *ngIf="displayInfo.numOfServiceOptions" class="line-item equipment-info-item">
  <div class="label">Service Options</div>
  <div class="value">
    <span>{{ displayInfo.firstServiceOptions }}</span>
    <ng-container *ngIf="displayInfo.numOfServiceOptions > 1">
      <span [nzTooltipTitle]="displayInfo.allServiceOptions" nz-tooltip
        style="margin-left: 5px;">(+{{displayInfo.numOfServiceOptions - 1}})</span>
    </ng-container>
  </div>
</div>
<div class="line-item equipment-info-item">
  <div class="label">
    Optional <i nz-icon nzType="info-circle" nzTheme="outline" nzTooltipPlacement="bottom"
      nzTooltipOverlayClassName="tooltip-info" style="margin-left: 10px;" class="clickable" nz-tooltip
      [nzTooltipTitle]="'The other carriers who have this equipment can also bid'"></i>
  </div>
  <div class="value">
    <span>{{ displayInfo.firstOptionalEquipments || 'N/A'}}</span>
    <ng-container *ngIf="displayInfo.numOfOptionalEquipments > 1">
      <span [nzTooltipTitle]="displayInfo.allOptionalEquipments" nz-tooltip
        style="margin-left: 5px;">(+{{displayInfo.numOfOptionalEquipments - 1}})</span>
    </ng-container>
    <a style="margin-left: 5px;" (click)="onBtnEditOptionalEquipments()">Update</a>
  </div>
</div>
<div *ngIf="onHold?.length" class="warning-on-hold-shipment">
  <div style="margin-right: 3px;">On-hold shipments:</div>
  <div *ngFor="let s of onHold" class="shipment-on-hold">{{ showShipmentWarpId(s) }}</div>
</div>
<div *ngIf="displayInfo?.serviceOptionsInfo?.length">
  <div class="line-item equipment-info-item">
    <div class="label">Service options </div>
    <div class="value" *ngIf="shouldAttention()">
        <span *ngFor="let s of getUniqueAttentionServiceOptions(displayInfo.serviceOptionsInfo)">
            <nz-tag nzColor="orange">{{ getServiceOptionName(s) }}</nz-tag>
        </span>
    </div>
    <a class="value" *ngIf="!isServiceOptionsVisible && totalServiceOptions > 0" (click)="toggleServiceOptions()" style="cursor: pointer">
      Show details (+{{ totalServiceOptions }})
    </a>
    <a class="value" *ngIf="isServiceOptionsVisible" (click)="toggleServiceOptions()" style="cursor: pointer">
      Hide details
    </a>
  </div>
  <div *ngIf="isServiceOptionsVisible">
    <div *ngFor="let item of displayInfo.serviceOptionsInfo" class="left5">
      {{item.warpId}} - {{item.stop}} - 
      <span *ngFor="let s of item.serviceOptions; first as isFirst">
        <span *ngIf="!isFirst">, </span>
        <span *ngIf="isAttentionServiceOption(s); else normalText">
          <nz-tag nzColor="orange">{{ getServiceOptionName(s) }}</nz-tag>
        </span>
        <ng-template #normalText>
          {{ getServiceOptionName(s) }}
        </ng-template>
      </span>
    </div>
  </div>
</div>