<div class="filter-condition">
  <div class="row">
    <div class="label">Time frame</div>
    <div class="value">{{txtDateTime}}</div>
  </div>
  <div class="row">
    <div class="label">Customers</div>
    <div class="value">
      <nz-tag *ngFor="let item of customers"><a [routerLink]="[routeAdminClientList, item.id]" target="_blank">{{item.name}}</a></nz-tag>
    </div>
  </div>
  <div class="row">
    <div class="label">Markets</div>
    <div class="value">
      <nz-tag *ngFor="let item of markets">{{item}}</nz-tag>
    </div>
  </div>
  <div class="row">
    <div class="label">Vehicles</div>
    <div class="value">
      <nz-tag *ngFor="let item of vehicles">{{item.name}}</nz-tag>
    </div>
  </div>
</div>

<ng-container *ngFor="let loader of routesLoaders">
  <nz-collapse nzGhost class="route-list">
    <nz-collapse-panel #pAddRoute [nzHeader]="tplHeaderAddRoute" [nzShowArrow]="false" [nzActive]="true">
      <ng-template #tplHeaderAddRoute>
        <div>
          <span style="font-size: 16px; font-weight: 500;">{{loader.name}}</span>
          <span class="left5">({{loader.listData().length}})</span>
          <span nz-icon nzType="right" nzTheme="outline" class="left10" [nzRotate]="pAddRoute.nzActive ? 90 : 0"></span>
        </div>
      </ng-template>
      <div *ngIf="loader.listData().length == 0" class="list-empty">{{loader.noDataText}}</div>
      <nz-table *ngIf="loader.listData().length > 0"
        [nzData]="loader.listData()"
        [nzLoading]="loader.isLoading()"
        [nzFrontPagination]="true"
        [nzShowPagination]="loader.listData().length > pageSize"
        [nzTotal]="loader.listData().length"
        [nzPageSize]="pageSize"
        #nzTable
        (nzCurrentPageDataChange)="onCurrentPageDataChange(loader.key, $event)"
        [nzBordered]="true"
      >
        <thead>
          <tr>
            <th>Route ID</th>
            <th>Customers</th>
            <th>First Pickup</th>
            <th>Last Delivery</th>
            <th>Vehicle</th>
            <th>
              <ng-container *ngIf="loader.key === 'recommend'">
                <div 
                  nz-checkbox 
                  [(ngModel)]="isCheckAllRecommend" 
                  (ngModelChange)="onCheckAllRecommend(loader, $event)"
                >
                  Select All
                </div>
              </ng-container>
              <ng-container *ngIf="loader.key === 'added'">
                Action
              </ng-container>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let item of nzTable.data">
            <td>
              <a [routerLink]="linkRoute(item)" target="_blank">{{item.code}}</a>
            </td>
            <td>
              <a *ngFor="let client of item.clients" class="customer" [routerLink]="linkCustomer(client)">{{client.name}}</a>
            </td>
            <td>
              <div>{{pickAddr(item)}}</div>
              <div class="pick-time">{{pickTime(item)}}</div>
            </td>
            <td>
              <div>{{dropAddr(item)}}</div>
              <div class="pick-time">{{dropTime(item)}}</div>
            </td>
            <td>{{vehicle(item)}}</td>
            <td>
              <a *ngFor="let action of loader.actions" (click)="action.fn(item)" [ngClass]="{'danger': action.isDanger}">{{action.label}}</a>
            </td>
          </tr>
        </tbody>
      </nz-table>
    </nz-collapse-panel>
  </nz-collapse>
</ng-container>
