import { BaseComponent } from "@abstract/BaseComponent";
import { Component, EventEmitter, Input, Output } from "@angular/core";
import { Const } from "@const/Const";
import { DateUtil } from "@services/date-utils";
import { ResponseBidSessionListItemUI } from "./interface";
import { DataRepoBidSession } from "@app/data-repo/bid-session";
import { getLinkDashboard } from "@services/routerlink";

@Component({
  selector: '[item-bid-session-component]',
  templateUrl: './index.html',
  styleUrls: [
    './style.scss'
  ]
})
export class ItemBidSessionComponent extends BaseComponent {
  private _item: ResponseBidSessionListItemUI;
  @Input() set item(val: ResponseBidSessionListItemUI) {
    this._item = val;
  }
  @Output() onItemDeleted = new EventEmitter<ResponseBidSessionListItemUI>();

  get item() {
    return this._item;
  }

  constructor(private repoBidSession: DataRepoBidSession) {
    super();
  }

  isToggle = false;
  onToggleContent() {
    this.isToggle = !this.isToggle;
  }

  public displayTimeWindow() {
    const timezone = this.item?.timeZone || 'America/Los_Angeles';
    const window = { from: this.item.fromTime, to: this.item.toTime };
    const text = DateUtil.displayTimeWindow(window, {
      timezone: timezone,
      format: 'MMM DD, H:mm',
      formatDateOnly: 'MMM DD'
    });
    return `${text} (${DateUtil.timezoneStandardToUsShort(timezone)})`;
  }

  get countCandidate() {
    return this.item.count?.candidate || 0;
  }

  get countSentCandidate() {
    return this.item.count?.sent || 0;
  }

  get countAssigned() {
    return this.item.count?.alreadyAssigned || 0;
  }

  get countHasBids() {
    return this.item.count?.alreadyBid || 0;
  }

  get countNoBid() {
    return this.item.count?.notBid || 0;
  }

  get detailRouterLink() { return [Const.routeAdminBidSessions, this.item.id] }

  get routerLink_createdUser() { return getLinkDashboard(this.item?.created?.by) }

  get createdTime() { return DateUtil.format(this.item.created?.when, 'MMM DD YYYY, H:mm a') }

  onBtnDeleteItem(item: ResponseBidSessionListItemUI) {
    this.confirmDeletion({
      message: `Delete bid session <b>${item.name}</b>?`,
      fnOk: () => this.deleteItem(item),
    });
  }

  private deleteItem(item: ResponseBidSessionListItemUI) {
    item.isLoading = true;
    this.repoBidSession.deleteSession(item.id).subscribe(
      resp => {
        this.onItemDeleted.emit(item);
        item.isLoading = false;
      }, err => {
        this.showErr(err);
        item.isLoading = false;
      }
    )
  }
}