import { Component } from "@angular/core";
import { BaseComponent } from "@abstract/BaseComponent";
import { Subscription } from "rxjs";
import { CarrierSaleDetailService } from "@app/admin/carrier-sale-detail/carrier-sale-detail.service";

@Component({
  selector: "carrier-sale-outreach",
  templateUrl: "./index.html",
  styleUrls: ["./index.scss"],
})
export class CarrierSaleOutreach extends BaseComponent {
  bid: any;
  jobHyperLink;
  displayInfo: any = {};
  private bidDetailSubscription: Subscription;
  onHold: string[] = []

  public tabSelected: number = 0;
  private tabIndexs = {
    'matched-carriers': 0,
    'external-posting': 1,
  }
  
  constructor(private carrierSaleDetailService: CarrierSaleDetailService) {
    super();
  }

  ngOnInit() {
    this.bidDetailSubscription = this.carrierSaleDetailService.bidDetail$.subscribe(bidDetail => {
      this.bid = bidDetail;
    });
  }

  onTabSelectedIndexChange() {
  }

  ngOnDestroy() {
    if (this.bidDetailSubscription) {
      this.bidDetailSubscription.unsubscribe();
    }
  }

  get isMatchedCarriersTab() {
    return this.tabSelected === this.tabIndexs['matched-carriers'];
  }

  get isExternalPostingTab() {
    return this.tabSelected === this.tabIndexs['external-posting'];
  }

  get shouldShowWarning(): boolean {
    return this.bid?.bidSessionIds?.length;
  }

  get bidSessions() {
    return this.bid?.bidSessions || [];
  }
 
}