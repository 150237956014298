import { Injectable } from '@angular/core';
import { Const } from '@const/Const';
import { ApiService } from '@services/api.service';
import { BidCandidate } from '@wearewarp/types/data-model';
import { BehaviorSubject, ReplaySubject, Subject } from 'rxjs';
import { Const as WarpConst } from '@wearewarp/universal-libs';

@Injectable({
  providedIn: 'root'
})
export class CarrierSaleDetailService {
  private bidDataSubject = new Subject<void>();

  private bidDetailSubject = new BehaviorSubject<any>(null);
  bidDetail$ = this.bidDetailSubject.asObservable();
  private carriersSubject = new BehaviorSubject<BidCandidate[]>(null);
  carriers$ = this.carriersSubject.asObservable();
  public isOverPickupTimeSubject: BehaviorSubject<boolean> = new BehaviorSubject(false);
  isOverPickupTime$ = this.isOverPickupTimeSubject.asObservable();
  public isOpenModalUpdatePickupTimeSubject: Subject<boolean> = new Subject();
  isOpenModalUpdatePickupTime$ = this.isOpenModalUpdatePickupTimeSubject.asObservable();

  public noteCountSubject = new ReplaySubject<{ [carrierId: string]: number }>();
  public notesSubject = new ReplaySubject<{ [carrierId: string]: any }>();

  constructor(private api: ApiService) {
    this.carriers$.subscribe(carriers => {
      this.countNotes();
    });
  }
  setBidDetail(bidDetail: any) {
    this.bidDetailSubject.next(bidDetail);
  }

  setCarriers(carriers: BidCandidate[]) {
    this.carriersSubject.next(carriers);
  }

  setOverPickupTime(isOverPickupTime: boolean) {
    this.isOverPickupTimeSubject.next(isOverPickupTime);
  }

  setOpenModalUpdatePickupTime(value: boolean) {
    this.isOpenModalUpdatePickupTimeSubject.next(value);
  }

  getDataObservable() {
    return this.bidDataSubject.asObservable();
  }

  emitGetDataBid() {
    this.bidDataSubject.next();
  }

  makeCost(data) {
    return {
      currency: {
        type: "USD",
        fxRate: null
      },
      transitCost: {
        rate: data.price,
        qty: 1,
        total: data.price
      },
      volumeDiscount: {
        type: "percentage",
        percentage: null,
        flatRate: null,
        qty: null,
        total: 0
      },
      subTotal: data.price,
      fuelCost: {
        type: "rpm",
        percentage: null,
        rpm: null,
        qty: null,
        total: 0
      },
      serviceOptions: [],
      negativeMarginReason: null,
      manager: null,
      grandTotal: data.price,
      usdConversion: 0
    }
  }

  private carrierTabFilterSubject = new BehaviorSubject<any>(null);

  getCarrierTabFilter() {
    return this.carrierTabFilterSubject.asObservable();
  }

  setCarrierTabFilter(data) {
    this.carrierTabFilterSubject.next(data);
  }

  emitGetListCarriers() {
    let tabFilter = this.carrierTabFilterSubject.getValue() || {};
    tabFilter['ts'] = new Date().getTime();
    this.carrierTabFilterSubject.next(tabFilter);
  }

  async countNotes() {
    const carrierIds = this.carriersSubject.getValue()?.map(carrier => carrier.carrierId || carrier.requestId);
   
    if (!carrierIds || carrierIds.length === 0) {
      this.noteCountSubject.next({});
      return;
    }
    let listSubjectIds = carrierIds.filter(carrierId => carrierId).map(carrierId => `CARRIER_${carrierId}`);
    const listSubjectWithBid = listSubjectIds.map(subjectId => `${subjectId}_BID_${this.bidDetailSubject.getValue().id}`);
    listSubjectIds = listSubjectIds.concat(listSubjectWithBid);
   
    //json to query string
    const query = listSubjectIds.map(subjectId => `subjectIds[]=${subjectId}`).join('&');
    const data = await this.api.GET(`${Const.APIURI_CONVERSATIONS}?${query}&subjectType=${WarpConst.ConversationSubjectType.carrier_in_bids}&type=${WarpConst.ConversationType.note}`).toPromise()
    const listData = data?.data?.list_data || [];

    //group note by subjectId
    const items = {};
    for (let note of listData) {
      const subjectId = note.subjectId;
      if (!items[subjectId]) items[subjectId] = [];
      items[subjectId].push(note);
    }
    //count by carrierId
    const countItems = {};
    for (let carrierId of carrierIds) {
      countItems[carrierId] = items[`CARRIER_${carrierId}`]?.length || 0;
      countItems[carrierId] += items[`CARRIER_${carrierId}_BID_${this.bidDetailSubject.getValue().id}`]?.length || 0;
    }
    this.notesSubject.next(items);
    this.noteCountSubject.next(countItems);
  }

}