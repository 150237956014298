<div *ngIf="isLoading && !hasData" class="nodata"><i nz-icon nzType="loading" nzTheme="outline"></i></div>
<div *ngIf="isError" class="nodata">Cannot fetch data, please try again later</div>

<ng-container *ngIf="hasData">
  <nz-collapse nzGhost class="section">
    <nz-collapse-panel #pBasic [nzHeader]="tplHeaderBasic" [nzShowArrow]="false" [nzActive]="true">
      <ng-template #tplHeaderBasic>
        <div class="section-title">
          Session info
          <i style="margin-left: 10px;" nz-icon nzType="right" nzTheme="outline" class="ant-collapse-arrow" [nzRotate]="pBasic.nzActive ? 90 : 0"></i>
        </div>
        <div class="flex1"></div>
        <button nz-button nzType="default" (click)="$event.stopPropagation(); onBtnEdit()">
          <span nz-icon nzType="edit" nzTheme="outline"></span>
          Update
        </button>
      </ng-template>
      <div class="row">
        <div class="label">Name</div>
        <div class="value">{{data.name}}</div>
      </div>
      <div class="row">
        <div class="label">Short description</div>
        <div class="value">{{data.descShort}}</div>
      </div>
      <div class="row">
        <div class="label">Long description</div>
        <div class="value">{{data.descLong}}</div>
      </div>
      <div class="row">
        <div class="label">Time frame</div>
        <div class="value">{{txtDateTime}}</div>
      </div>
      <div class="row">
        <div class="label">Customers</div>
        <div class="value">
          <nz-tag *ngFor="let item of customers"><a [routerLink]="[routeAdminClientList, item.id]" target="_blank">{{item.name}}</a></nz-tag>
        </div>
      </div>
      <div class="row">
        <div class="label">Markets</div>
        <div class="value">
          <nz-tag *ngFor="let item of markets">{{item}}</nz-tag>
        </div>
      </div>
      <div class="row">
        <div class="label">Vehicles</div>
        <div class="value">
          <nz-tag *ngFor="let item of vehicles">{{item.name}}</nz-tag>
        </div>
      </div>
    </nz-collapse-panel>
  </nz-collapse>

  <nz-collapse nzGhost class="section">
    <nz-collapse-panel #pRoute [nzHeader]="tplHeaderRoutes" [nzShowArrow]="false">
      <ng-template #tplHeaderRoutes>
        <div class="section-title">
          Added routes ({{routes.length}})
          <i style="margin-left: 10px;" nz-icon nzType="right" nzTheme="outline" class="ant-collapse-arrow" [nzRotate]="pRoute.nzActive ? 90 : 0" class="ic-edit clickable"></i>
        </div>
        <div class="flex1"></div>
        <button nz-button nzType="default" (click)="$event.stopPropagation(); addRoute()">
          <span nz-icon nzType="edit" nzTheme="outline"></span>
          Update
        </button>
      </ng-template>
      <div *ngFor="let route of routes" class="row">
        <a [routerLink]="[routeAdminDispatchList, route.id]" target="_blank" style="width: 120px; margin-right: 50px;">{{route.code}}</a>
        <a [routerLink]="[routeAdminCarrierSales, route.bidId]" target="_blank">View bid</a>
      </div>
      <i *ngIf="routes.length == 0" class="grey">
        No route added
      </i>
    </nz-collapse-panel>
  </nz-collapse>

  <nz-collapse nzGhost class="section">
    <nz-collapse-panel #pCarrier [nzHeader]="tplHeaderCarriers" [nzShowArrow]="false">
      <ng-template #tplHeaderCarriers>
        <div class="section-title">
          Added carriers ({{carriers.length}})
          <i style="margin-left: 10px;" nz-icon nzType="right" nzTheme="outline" class="ant-collapse-arrow" [nzRotate]="pCarrier.nzActive ? 90 : 0"></i>
        </div>
        <div class="flex1"></div>
        <button nz-button nzType="default" (click)="$event.stopPropagation(); addCarrier()">
          <span nz-icon nzType="edit" nzTheme="outline"></span>
          Update
        </button>
      </ng-template>
      <ng-container *ngIf="carriers.length">
        <div *ngFor="let carrier of carriersOfPage" class="row">
          <a [routerLink]="[routeAdminCarrierList, carrier.id]" target="_blank">{{carrier.name}}</a>
        </div>
        <div class="pagination">
          <nz-pagination 
            [nzPageIndex]="pageIndex" 
            [nzPageSize]="pageSize" 
            [nzTotal]="carriers.length"
            (nzPageIndexChange)="changePageIndex($event)"
          >
          </nz-pagination>
        </div>
      </ng-container>
      <i *ngIf="carriers.length == 0" class="grey">
        No carrier added
      </i>
    </nz-collapse-panel>
  </nz-collapse>

  <bid-session-outreach [bidSessionId]="id"></bid-session-outreach>
  <div class="g-responsed">
    <div class="responsed-title">
      Responded  
    </div>
    <div class="desc">
      Total carriers: {{totalCarrier}},
      Total bids: {{totalBid}}
    </div>
  </div>

  <div class="carrier-selection">
    <bid-session-carriers [bidSessionId]="id"></bid-session-carriers>
  </div>
</ng-container>