import { Component } from "@angular/core";
import { BaseComponent } from "@abstract/BaseComponent";
import { Subscription } from "rxjs";
import { BidSessionDetailService } from "@app/admin/bid-sessions/bid-session-detail.service";
import { Const } from "@const/Const";
import dayjs from "dayjs";
import { DateUtil } from "@services/date-utils";
import { InputHelper } from "@services/input-helper";
import { ResponseBidSessionDetail } from "@wearewarp/types-server-admin/carrier-bid/bid-session";

@Component({
  selector: "bid-session-wave",
  templateUrl: "./index.html",
  styleUrls: ["./index.scss"],
})
export class BidSessionWave extends BaseComponent {
  session: ResponseBidSessionDetail;
  bidPlaceCount: number = 0;
  isbidPlaceCountLoading = false;
  isLoadWave: boolean = false;
  private bidDetailSubscription: Subscription;
  wares = [
  ]
  sentBidSummary: {
    total_carrier?: number,
    total_sms?: number,
    total_email?: number,
    cost_sms?: string
  } = {}
  historyAddCarrier: Array<{ summary: string, datetime?: string }> = [];
  numOfCarrierMatched: number = 0;
  constructor(private bidSessionDetailService: BidSessionDetailService) {
    super();
  }

  ngOnInit() {
    this.bidDetailSubscription = this.bidSessionDetailService.sessionDetail$.subscribe(sessionDetail => {
      this.session = sessionDetail;
      this.getWaveList();
      this.getBidPlaceCount();
    });
  }

  get bidSessionId() {
    return this.session?.id;
  }

  ngOnDestroy() {
    if (this.bidDetailSubscription) {
      this.bidDetailSubscription.unsubscribe();
    }
  }

  private getBidPlaceCount() {
    this.isbidPlaceCountLoading = true;
    const url = `${Const.APIURI_BID_SESSIONS}/${this.session.id}/session-place-count`;
    this.api.GET(url).subscribe((response) => {
      this.isbidPlaceCountLoading = false;
      this.bidPlaceCount = response.data?.count;
    }, err => {
      this.showErr(err);
      this.isbidPlaceCountLoading = false
    })
  }

  private getWaveList() {
    this.isLoadWave = true;
    const url = `${Const.APIURI_BID_SESSIONS}/${this.session.id}/wave-list`;
    this.api.GET(url).subscribe((response) => {
      this.isLoadWave = false;
      this.wares = this.processWaveItem(response?.data?.listOfDataWave ?? []);
      this.numOfCarrierMatched = response?.data?.numOfCarrierMatched ?? 0;
      this.sentBidSummary = this.formatSentBidSummary(response?.data?.sentBidSummary);
      this.historyAddCarrier = this.formatHistoryAddCarrier(response?.data?.historyAddCarrier ?? []);
    }, err => {
      this.showErr(err);
      this.isLoadWave = false;
    });
  }

  private formatSentBidSummary(sentBidSummary: any) {
    return {
      total_carrier: sentBidSummary?.total_carrier ?? 0,
      total_sms: sentBidSummary?.total_sms ?? 0,
      total_email: sentBidSummary?.total_email ?? 0,
      cost_sms: sentBidSummary?.cost_sms ? this.formatMoney(sentBidSummary.cost_sms) : '',
    };
  }

  private formatHistoryAddCarrier(historyAddCarrier: any[]) {
    const sourceMap = {
      'GEO_FEATURE_ASSIGNED': 'radius',
      'GEO_FEATURE_PLACED': 'radius',
      'GEO_FEATURE': 'radius',
      'DEDICATED_POOL': 'dedicated pool',
      'SAME_MARKET': 'market',
      'EXTERNAL_MATCHING': 'truck search',
      'COVERAGE_AREA': 'coverage area',
      'PREFERRED_LANE': 'preferred lane',
      'MANUAL': 'manual',
      'LOAD_BOARD_PUBLIC': 'public load board',
      'loadBoard': 'load board carrier portal',
      'ASSIGNED_SAME_LANE': 'assigned same lane',
      'BID_TO_SESSION': 'bid to session',
      'BID_SESSION': 'bid session'
    };

    return historyAddCarrier.map(item => {
      const numOfCarrier = item.numOfCarrier ?? 0;
      const carrierText = `${numOfCarrier} ${numOfCarrier > 1 ? 'carriers' : 'carrier'}`;
      const sourceText = sourceMap[item.source] ? `Source: ${sourceMap[item.source]}` : `Source: ${item.source ?? 'N/A'}`;
      const createdByText = item.source === 'MANUAL' || item.source === 'loadBoard' ? ` - ${item.createdBy ?? 'N/A'}` : '';
      const txt = `${item.source === 'MANUAL' || item.source === 'loadBoard' ? 'Manual Added' : 'Auto-Added'} ${carrierText} - ${sourceText}${createdByText}`;
      
      const obj: any = { summary: txt };
      const createdWhen = item.createdWhen ? DateUtil.dateToString(item.createdWhen, Const.FORMAT_GUI_DATETIME_V4) : '';
      if (createdWhen) {
        obj.datetime = createdWhen;
      }
      return obj;
    });
  }

  private processWaveItem(list) {
    let ret = [];
    for (const item of list) {
      const carrierCount = item.total_carrier || 0;
      const sendSmsCount = item.total_sms || 0;
      const sendEmailCount = item.total_email || 0;
      const sendPushCount = item.total_push || 0;
      const totalSent = item.total_sent || 0;
      const timeString = item._id?.replace('BID_QUEUE:', '');
      const when = item?.when?.[0];
      let time = 'manual'
      if (when) {
        time = DateUtil.dateToString(when, Const.FORMAT_GUI_DATETIME_SHORT);
      } else if (timeString) {
        const date = dayjs.utc(timeString, "YYYYMMDDHHmmss").local();
        time = date.format("M/D/YY, h:mm A");
      }
      
      ret.push({
        id: item._id,
        time,
        status: item.status,
        summary: item.summary,
        carrierCount,
        sendEmailCount,
        sendSmsCount,
        sendPushCount,
        createdBy: item.manualQueueBy ? item.manualQueueBy : 'system',
      });
    }
    return ret;
  }

  formatMoney(amt: number | string) {
    return InputHelper.formatMoney2(`${amt || 0}`);
  }
}